import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import de from '@/i18n/Sprachen/de.json'
import en from '@/i18n/Sprachen/en.json'

const messages = {
    de,
    en
}

const supportedLanguages = [
    'de',
    'en'
]

let locale = 'en'

if (VueCookies.isKey('locale') && supportedLanguages.includes(VueCookies.get('locale')))
    locale = VueCookies.get('locale')

export default new VueI18n({
    locale: locale,
    messages
});