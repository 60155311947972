import Vue from 'vue'

export default {

    notification: () => {
        if (('Notification' in window)) {

            const icons = [
                'notif-man',
                'notif-woman',
                'notif-nohair',
                'notif-red-jacket',
            ]

            return {
                permission: async() => {
                    const desicion = await Notification.requestPermission()

                    logger.log(`this is desicion from user: ${ desicion }`)
                },

                push: (title, message, withIcon) => {
                    let img = ''

                    if (withIcon) {
                        const index = Math.floor(Math.random() * icons.length)
                        img = require(`@/assets/images/benachrichtigungen/${ icons[index] }.png`)
                    }

                    const notif = new Notification(title, {
                        body: message,
                        icon: img,
                        requireInteraction: true,
                    })

                    logger.log(`this is notif result: `, notif)
                },
            }
        }
    },

    /**
     *
     * @param that
     * @param data
     * @returns {any}
     */
    checkForceOrLastTransfer(that, data) {
        return (data.hasOwnProperty('force') && data.force)
    },

    /**
     *
     * @param that
     * @param data
     * @returns {any}
     */
    checkSocketRequestedMemberidFromStore(that, data) {
        return that.$store.getters.isAuthenticated && data.memberid === that.$store.getters.getActingMemberid
    },

    /**
     *
     * @param that
     * @param data
     * @returns {any}
     */
    checkSocketRequestedMemberidFromRoute(that, data) {
        return that.$store.getters.isAuthenticated && that.$route.params.hasOwnProperty('memberid') && that.$route.params.memberid && parseInt(data.memberid) === parseInt(that.$route.params.memberid)
    },

    /**
     *
     * @param value
     * @param withSound
     */
    copyToClipboard: async(value, withSound = false) => {

        logger.log(`copy me to clipboard: ${ value }`)

        await navigator.clipboard.writeText(value)

        if (withSound) {
            new Promise(resolve => {
                setTimeout(() => {
                    const audio = new Audio(require(`@/assets/sounds/pop_up_tone_short_005.mp3`))
                    audio.play()

                    resolve()

                }, 50)
            })
        }
    },

    /**
     *
     * @param keys
     * @param value
     * @param search
     * @param item
     */
    customFilter: (keys, value, search, item) => {

        let test = false

        for (let i in keys) {
            if (keys.hasOwnProperty(i)) {
                const key = keys[i]

                //logger.log(`this is in for item[key]: ${ item[key] }, key: ${ key } `, item)

                if (item.hasOwnProperty(key) && item[key] !== null && item[key] !== undefined && item[key].toString().toLowerCase().includes(search.toLowerCase())) {
                    test = true
                    break
                }

            }
        }

        return test
    },

    /**
     *
     * @param url
     * @param blank
     */
    windowOpen: (url, blank = false) => {
        const win = window.open()
        win.location = url

        if (blank) {
            win.name = '_blank'
            win.opener = null
        }
    },
}