<template>
    <v-container>
        <v-navigation-drawer
            app
            clipped
            :permanent="menuState"
            v-if="isAuthenticated && getSocketConnection"
            v-model="menuState"
        >
            <div class="fill-height d-flex flex-column">
                <div>
                    <v-list-item style="cursor: pointer" @click.stop="subMenuState = !subMenuState">
                        <v-list-item-avatar :color="getColors.secondary2" class="white--text">
                            {{ getUsernameTexts.initials.toUpperCase() }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ getUsernameTexts.fullname }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-menu
                            v-model="subMenuState"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            tile
                        >
                            <v-card>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('common.submenu.heading', { name: getUsernameTexts.firstname }) }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ getUsernameTexts.company }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <div v-if="getUser.id === 767">
                                    <v-divider/>
                                    <v-card-actions>
                                        <v-btn
                                            plain
                                            small
                                            :color="getColors.primary"
                                            @click="cacheClearForAll"
                                        >
                                            {{ $t('common.submenu.cacheClearAll') }}
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                                <v-divider/>
                                <div>
                                    <v-card-actions>
                                        <v-select
                                            class="px-2"
                                            v-model="selectedLocale"
                                            :items="localeItems"
                                            @change="changeLocale"
                                        />
                                    </v-card-actions>
                                </div>
                                <v-divider/>
                                <v-card-actions>
                                    <v-btn
                                        plain
                                        small
                                        :color="getColors.error"
                                        @click="cacheClear"
                                    >
                                        {{ $t('common.submenu.cacheClear') }}
                                    </v-btn>
                                    <v-btn
                                        plain
                                        small
                                        :color="getColors.secondary1"
                                        @click="logout"
                                    >
                                        {{ $t('common.submenu.logout') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-list-item>
                </div>
                <div>
                    <v-divider/>
                </div>
                <div>
                    <v-list dense>
                        <div v-for="(menuItem, menuItemIndex) in menuItems" :key="`${menuItemIndex}-menuListItem`">
                            <v-list-item-title
                                v-if="menuItem.separator"
                                class="pl-4 pt-4 pb-2"
                                v-html="menuItem.separator"
                            />
                            <v-divider v-if="menuItem.separator" :inset="false"/>
                            <v-list-item
                                class="py-1"
                                :inactive="menuItem.hasOwnProperty('inactive') && menuItem.inactive()"
                                :ripple="!menuItem.hasOwnProperty('inactive') || !menuItem.inactive()"
                                :selectable="!menuItem.hasOwnProperty('inactive') || !menuItem.inactive()"
                                :to="menuItem.url"
                                :color="getColors.secondary1"
                                :style="(menuItem.hasOwnProperty('inactive') && menuItem.inactive() && 'cursor: not-allowed')"
                            >
                                <v-list-item-icon class="mr-2 pb-1">
                                    <v-icon size="21">
                                        {{ menuItem.icon }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ menuItem.title }}
                                </v-list-item-title>
                            </v-list-item>
                        </div>
                    </v-list>
                </div>
            </div>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import permissions from '@/router/permissions'
import { mapGetters } from 'vuex'

export default {
    name: 'Menu',
    props: {
        menu: Boolean,
        subMenu: Boolean,
    },
    data: function () {
        return {
            selectedLocale: this.$i18n.locale,
            //localeItems: [],
            subMenuState: false,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActAsMember',
            'getUsernameTexts',
            'isAuthenticated',
            'getUser',
            'getSocketConnection',
        ]),
        menuState: {
            get() {
                return this.menu
            },
            set(newVal) {
                this.$emit('update:menu', newVal)

                if (!newVal)
                    this.subMenuState = newVal
            },
        },
        localeItems: function () {
            return [
                { text: this.$i18n.t('langs.de'), value: 'de' },
                { text: this.$i18n.t('langs.en'), value: 'en' },
            ]
        },
        menuItems: function () {
            return [
                {
                    title: this.$i18n.tc('common.menu.items.members', 2),
                    icon: 'mdi-account-group',
                    url: '/members',
                    separator: this.$i18n.t('common.menu.headings.members'),
                    permission: () => {
                        return true
                    },
                },
                {
                    title: this.$i18n.tc('common.menu.items.members', 1),
                    icon: (!this.getActAsMember ? 'mdi-account-lock' : 'mdi-account'),
                    url: `/member/${ this.$store.getters.getActingMemberid }`,
                    inactive: () => {
                        return !this.getActAsMember
                    },
                },
                {
                    title: this.$i18n.t('common.menu.items.houses'),
                    icon: 'mdi-home-city',
                    url: '/houses',
                    separator: this.$i18n.t('common.menu.headings.content'),
                    permission: () => {
                        return true
                    },
                },
                {
                    title: this.$i18n.t('common.menu.items.properties'),
                    icon: 'mdi-home-group',
                    url: '/properties',
                    permission: () => {
                        return true
                    },
                },
                {
                    title: this.$i18n.t('common.menu.items.clients'),
                    icon: 'mdi-storefront',
                    url: '/clients',
                    permission: () => {
                        return true
                    },
                },
                {
                    title: this.$i18n.t('common.menu.items.cronqueue'),
                    icon: 'mdi-tray',
                    url: '/cron-queue-jobs',
                    separator: this.$i18n.t('common.menu.headings.queue'),
                    permission: () => {
                        return true
                    },
                },
                {
                    title: this.$i18n.t('common.menu.items.jobs'),
                    icon: 'mdi-tray-full',
                    url: '/jobs',
                    permission: () => {
                        return true
                    },
                },
                {
                    title: this.$i18n.t('common.menu.items.creator'),
                    icon: 'mdi-database-plus',
                    url: '/job-creator',
                    permission: () => {
                        return permissions.job_creator()
                    },
                },
                {
                    title: this.$i18n.t('common.menu.items.channels'),
                    icon: 'mdi-view-dashboard',
                    url: '/channels',
                    separator: this.$i18n.t('common.menu.headings.channels'),
                    permission: () => {
                        return true
                    },
                },
            ]
        },
    },
    methods: {
        changeLocale() {
            this.$store.commit('SET_LOCALE', this.selectedLocale)
            this.subMenuState = false

            //utils.notification().push(this.$t('common.words.language'), this.$t('notifications.languageChange', { language: this.$i18n.t(`langs.${ this.$i18n.locale }`) }), true)
        },
        async cacheClear() {
            this.subMenuState = false
            await this.$store.dispatch('cacheClear')
        },
        async cacheClearForAll() {
            this.subMenuState = false
            this.$socket.emit('broadcastEmitter', { method: 'cacheClearForAll' })
        },
        async logout() {
            this.subMenuState = false
            await this.$store.dispatch('logout')
        },
    },
}
</script>
