export default [
    {
        path: '/login',
        name: 'Login',
        meta: {
            breadcrumb: false,
        },
        component: () => import('@/views/Anmelden.vue'),
    },
    {
        path: '/',
        name: 'Home',
        meta: {
            requiresAuth: true,
            breadcrumb: false,
        },
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/licenses',
        name: 'Licenses',
        meta: {
            breadcrumb: false,
        },
        component: () => import('@/views/Lizenzen.vue'),
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        meta: {
            breadcrumb: false,
        },
        component: () => import('@/views/Fehlerseiten/ZugriffVerweigert403.vue'),
    },
    {
        path: '*',
        name: 'NotFound',
        meta: {
            breadcrumb: false,
        },
        component: () => import('@/views/Fehlerseiten/NichtGefunden404.vue'),
    },
]