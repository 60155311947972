import common from '@/router/routes/common'
import members from '@/router/routes/members'
import channels from '@/router/routes/channels'
import houses from '@/router/routes/houses'
import properties from '@/router/routes/properties'
import clients from '@/router/routes/clients'
import queue from '@/router/routes/queue'

export default [
    ...common,
    ...members,
    ...channels,
    ...houses,
    ...properties,
    ...clients,
    ...queue,
]