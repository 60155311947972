import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import VueSocketIO from 'vue-socket.io'
import io from 'socket.io-client'
import VueCookies from 'vue-cookies'
import i18n from './i18n/index'

// TODO: Implement the crypto-js (browseritfy) for permissions in store
//SEE: https://github.com/crypto-browserify/crypto-browserify

Vue.use(VueCookies)
Vue.$cookies.config('30d', '', '', true)

const baseUrl = process.env.VUE_APP_API_URL
const debug = process.env.VUE_APP_DEBUG === 1

Vue.use(new VueSocketIO({
    debug: (debug && false),
    connection: io('https://api.feondi.de:2020', { transports: ['websocket', 'polling'] }),
}))

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
})

Vue.config.productionTip = debug

Axios.defaults.headers.common['Authorization'] = `Bearer ${ store.state.token }`
Axios.defaults.baseURL = baseUrl
Axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'

import importedEnums from '@/utils/enums'

//Info: Make the enums object globally available
Object.defineProperty(global, 'enums', {
    configurable: false,
    writable: false,
    value: Object.create(importedEnums),
})

import customLogger from '@/utils/logger'

Object.defineProperty(global, 'logger', {
    configurable: false,
    writable: false,
    value: Object.create(customLogger),
})

import utils from '@/utils/utils'

Object.defineProperty(global, 'utils', {
    configurable: false,
    writable: false,
    value: Object.create(utils),
})

if (!debug)
    console.log(`the best admin you've ever seen is running in production mode! 🤖`)

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
