<template>
    <v-main class="main-bg pt-0">
        <div class="fill-height d-flex flex-column pr-3" v-if="getSocketConnection">
            <Breadcrumbs/>
            <router-view class="mt-1"/>
        </div>
        <SocketConnection v-else/>
    </v-main>
</template>

<script>
import Breadcrumbs from '@/components/Allgemein/Breadcrumbs'
import SocketConnection from '@/components/Allgemein/Socketverbindung.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Main',
    components: {
        Breadcrumbs,
        SocketConnection,
    },
    computed: {
        ...mapGetters([
            'getUser',
            'getMember',
            'isAuthenticated',
            'getSocketConnection',
            'getColors',
            'getActAsMember',
        ]),
    },
    methods: {},
}
</script>
