import ChannelsService from '@/services/channels.service'

export default {

    /**
     *
     * @param context
     * @param force
     * @returns {Promise<void>}
     */
    getAndSetSubChannels: async(context, force = false) => {

        if (force || !context.getters.getSubChannels.length) {
            await context.dispatch('setLoaders', { subChannels: true })

            const response = await ChannelsService.getSubChannels()

            if (response)
                context.commit('SET_SUBCHANNELS', response)

            await context.dispatch('setLoaders', { subChannels: false })
        }
    },

    /**
     *
     * @param context
     * @param force
     * @returns {Promise<void>}
     */
    getAndSetPropertyChannels: async(context, force = false) => {

        if (force || !context.getters.getPropertyChannels.length) {
            await context.dispatch('setLoaders', { propertyChannels: true })

            const response = await ChannelsService.getPropertyChannels()

            if (response)
                context.commit('SET_PROPERTY_CHANNELS', response)

            await context.dispatch('setLoaders', { propertyChannels: false })
        }
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    getAndSetChannelPropertiesMultipleMembers: async(context, payload) => {

        //if ((payload.force || !context.getters.getChannelProperties.length) && payload.memberids.length && payload.ssid > 0) {
            const ssid = payload.ssid
            const memberids = payload.memberids

            if (memberids.length && ssid) {
                await context.dispatch('setLoaders', { channelpropertiesmultiplemember: true })

                const response = await ChannelsService.getChannelPropertiesMultipleMembers({
                    ssid: ssid,
                    memberids: memberids,
                })

                if (response)
                    context.commit('SET_CHANNELPROPERTIES', response)

                await context.dispatch('setLoaders', { channelpropertiesmultiplemember: false })
            }
        //}
    },
}