export default [
    {
        path: '/houses',
        name: 'Houses',
        meta: {
            requiresAuth: true,
            breadcrumb: true,
            menuGroup: 'houses'
        },
        component: () => import('@/views/Haeuser.vue'),
    },
    {
        path: '/house/:houseid',
        name: 'House',
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumb: true,
            menuGroup: 'houses'
        },
        component: () => import('@/views/Haus.vue'),
    },
]