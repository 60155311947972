import permissions from "@/router/permissions";

export default [
    {
        path: '/jobs',
        name: 'Jobs',
        meta: {
            requiresAuth: true,
            breadcrumb: true,
        },
        component: () => import('@/views/Uebertragungen.vue'),
    },
    {
        path: '/job-creator',
        name: 'Job-Creator',
        meta: {
            requiresAuth: true,
            breadcrumb: true,
            permission: () => {
                return permissions.job_creator()
            },
        },
        component: () => import('@/views/Uebertragungserstellungsassitent.vue'),
    },
    {
        path: '/cron-queue-jobs',
        name: 'Cron queue jobs',
        meta: {
            requiresAuth: true,
            breadcrumb: true,
        },
        component: () => import('@/views/GeplanteUebertragungen.vue'),
    },
]