<template>
    <v-footer class="py-4" v-if="!isAuthenticated" :color="getSocketConnection ? getColors.primary : getColors.error">
        <div class="d-flex w-100">
            <div
                class="d-flex flex-row align-center"
                :class="(getSocketConnection && 'cursor-pointer')"
                @click.stop="$router.push({path: (getSocketConnection && $route.name && $route.name.toLowerCase() !== 'login' ? '/login' : '/')})"
            >
                <v-icon class="mr-4" large color="#ffffff">
                    mdi-account-lock-outline
                </v-icon>
                <div class="lighten-2 white--text" v-html="$t(`common.words.${(getSocketConnection ? 'readyToLogin' : 'notConnected')}`)"/>
            </div>

            <div class="ml-auto d-flex align-center" v-if="$route.name && $route.name.toLowerCase() !== 'licenses'">
                <div
                    @click.stop="$router.push({path: '/licenses'})"
                    class="lighten-2 white--text cursor-pointer"
                    v-html="$t('common.menu.items.licenses')"
                />
            </div>
        </div>
    </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Footer',
    computed: {
        ...mapGetters([
            'isAuthenticated',
            'getSocketConnection',
            'getColors',
        ]),
    },
}
</script>
