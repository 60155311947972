import auth from '@/store/mutations/auth'
import common from '@/store/mutations/common'
import members from '@/store/mutations/members'
import channels from '@/store/mutations/channels'
import queue from '@/store/mutations/queue'

export default {
    ...auth,
    ...common,
    ...members,
    ...channels,
    ...queue,
}