export default {
    SET_TOKEN: ((state, payload) => {
        state.token = payload
        state.isAuthenticated = true
    }),
    SET_USER: ((state, payload) => {
        state.user = payload
        state.userNameTexts.initials = payload.firstname.charAt(0) + payload.name.charAt(0)
        state.userNameTexts.firstname = payload.firstname
        state.userNameTexts.lastname = payload.name
        state.userNameTexts.fullname = payload.firstname + ' ' + state.userNameTexts.lastname
        state.userNameTexts.company = payload.company
        state.actingMemberid = payload.id
    }),
}