<template>
    <v-container v-if="this.$store.getters.getBanner.message" style="position:fixed; top:65px; right:0; z-index:9999">
        <v-row class="justify-end">
            <v-col cols="auto">
                <v-slide-x-reverse-transition v-if="isAuthenticated">
                    <v-banner
                        app
                        elevation="2"
                        single-line
                        :color="getColors[getBanner.type]"
                    >
                        <v-icon
                            slot="icon"
                            color="white"
                            size="36"
                        >
                            {{ getBanner.icon }}
                        </v-icon>
                        <span style="color: white">
                            {{ getBanner.message }}
                        </span>
                        <template v-slot:actions="{ dismiss }">
                            <v-btn
                                text
                                color="white"
                                @click="closeBanner(dismiss)"
                            >
                                {{ getBanner.btnText }}
                            </v-btn>
                            <v-btn
                                v-if="getBanner.action !== null"
                                text
                                color="white"
                                @click="getBanner.action"
                            >
                                {{ getBanner.actionBtnText }}
                            </v-btn>
                        </template>
                    </v-banner>
                </v-slide-x-reverse-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Banner',
    computed: {
        ...mapGetters([
            'getColors',
            'getBanner',
            'isAuthenticated',
        ]),
    },
    methods: {
        closeBanner(dismiss) {
            dismiss()
            this.$store.commit('RESET_PROP', 'banner')
            this.$store.commit('RESET_PROP', 'loaders')
        },
    },
}
</script>
