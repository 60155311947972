export default {
    /**
     *
     * @param state
     * @returns {[]}
     */
    getSubChannels: state => {
        return state.subChannels
    },

    /**
     *
     * @param state
     * @returns {[]}
     */
    getPropertyChannels: state => {
        return state.propertyChannels
    },

    /**
     *
     * @param state
     * @returns {[]}
     */
    getChannelMembers: state => {
        return state.channelMembers
    },
}