export default {

    /**
     *
     * @param state
     * @returns {[]}
     */
    getMembers: state => {
        return state.members
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getMember: state => {
        return state.member
    },

    /**
     *
     * @param state
     * @returns {boolean}
     */
    getActAsMember: state => {
        return state.actAsMember
    },

    /**
     *
     * @param state
     * @returns {number}
     */
    getActingMemberid: state => {
        return state.actingMemberid
    },

    /**
     *
     * @param state
     * @returns {[]}
     */
    getMemberChannels: state => {
        return state.memberChannels
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getMemberChannel: state => {
        return state.memberChannel
    },

    /**
     *
     * @param state
     * @returns {[]}
     */
    getInterfaces: state => {
        return state.interfaces
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getInterface: state => {
        return state.interface
    },

    /**
     *
     * @param state
     * @returns {[]}
     */
    getChannelProperties: state => {
        return state.channelProperties
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getPolicies: state => {
        return state.policies
    },

    /**
     *
     * @param state
     * @returns {[]}
     */
    getClients: state => {
        return state.clients
    },
}