const debug = process.env.VUE_APP_DEBUG === 1

export default {

    //UI
    socketConnection: true,
    colors: {
        primary: '#34495e',
        primaryOpacity05: 'rgba(52,73,94,0.5)',
        secondary1: '#3867d6',
        secondary2: '#8e44ad',
        warning: '#f39c12',
        error: '#e74c3c',
        success: '#16A085',
    },
    tab: 0,
    step: 1,

    //UX
    dialog: {
        state: false,
        data: {},
    },
    alert: {
        type: '',
        message: '',
    },
    banner: {
        type: '',
        message: '',
        icon: '',
        closeBtnText: '',
        action: null,
        actionBtnText: '',
    },

    //Routing
    routing: {
        fullPath: '',
        params: {},
    },

    //Application
    loaders: {},
    disables: {},
    messages: {},
    key: '',
    task: {},
    isAuthenticated: debug,
    token: '',
    user: {},
    userNameTexts: {
        initials: '',
        firstname: '',
        lastname: '',
        fullname: '',
        company: '',
    },
    breadcrumbs: [],
    actingMemberid: 0,
    actAsMember: false,
    locale: 'en',

    //Forms & Fields
    chips: {},
    models: [],
    form: {},

    //Members
    members: [],
    member: {},
    policies: {},
    memberChannels: [],
    memberChannel: {},
    clients: [],
    client: {},

    //Channels
    interfaces: [],
    interface: {},
    channelProperties: [],
    channelProperty: {},
    channelMembers: [],
    subChannels: [],

    //Queue
    propertyChannels: [],
    propertyChannelMembers: [],
    jobCreation: {
        ssid: 0,
        channelName: '',
        textid: '',
        members: {},
        job: '',
        success: '',
        redirect: false,
        photosCacheClear: false,
    },
}