<template>
    <v-container class="d-flex fill-height flex-row align-center justify-center">
        <div>
            <v-alert
                prominent
                type="error"
                :color="$store.getters.getColors.primary"
                class="pa-10 d-flex"
                elevation="4"
            >
                <div class="pl-4">
                    <div>The feondi-admin server is currently down.</div>
                    <div>We try to establish the connection.</div>
                </div>
            </v-alert>
        </div>
    </v-container>
</template>

<script>

export default {
    name: 'SocketConnection',
}
</script>
