import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import guards from './guards'

Vue.use(VueRouter)

/**
 *
 * @type {VueRouter}
 */
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL || '/',
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeResolve((to, from, next) => guards.beforeResolve(to, from, next))

router.afterEach(async(to, from) => await guards.afterEach(to, from))

export default router
