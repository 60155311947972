<template>
    <v-btn
        v-if="getActAsMember && isAuthenticated && Object.keys(getMember).length"
        :disabled="$route.meta.menuGroup === 'members'"
        @click="stopActingAsMember"
        class="pa-2"
        text
        plain
        small
        :ripple="false"
    >
        <v-icon small class="pr-2" :color="getButtonDisplayValues().iconColor">
            {{ getButtonDisplayValues().iconName }}
        </v-icon>
        <span :style="`color: ${getButtonDisplayValues().iconColor}`">
            {{ getButtonDisplayValues().buttonText }}
        </span>
    </v-btn>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'ActAsMemberButton',
    props: {
        memberidToActAs: {
            type: Number,
            required: false,
        },
        withColor: {
            type: Boolean,
            required: false,
            default: false,
        },
        withMemberid: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters([
            'getColors',
            'isAuthenticated',
            'getActAsMember',
            'getActingMemberid',
            'getMember',
            'getMembers',
        ]),
    },
    methods: {
        async stopActingAsMember() {
            await this.$store.dispatch('stopActingAsMember', true)
        },
        getButtonDisplayValues() {
            let buttonText = ''
            let iconName = 'mdi-account-convert-outline'
            let iconColor = (this.withColor ? this.getColors.secondary1 : '#ffffff')

            const acting = this.$i18n.t('actAsMember.actingAs', {
                name: this.getMember.name,
                memberid: this.getActingMemberid
            })
            const stopActing = this.$i18n.t('actAsMember.stopActing', {
                name: this.getMember.name,
                memberid: this.getActingMemberid
            })

            if (this.getActAsMember && this.getActingMemberid && this.getMember.name) {
                iconName = 'mdi-account-arrow-left-outline'
                buttonText = stopActing

                if (this.$route.meta.menuGroup === 'members')
                    buttonText = acting

                iconColor = (this.withColor ? this.getColors.secondary2 : '#ffffff')
            }

            return {
                iconName: iconName,
                iconColor: iconColor,
                buttonText: buttonText,
            }
        },
    },
}
</script>
