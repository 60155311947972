import communicate from '@/services/communicate.service'

export default {
    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getMembers() {
        return await communicate.request(enums.GET, 'members')
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getChannelMembers(ssid) {
        return await communicate.request(enums.GET, 'channelmembers/' + ssid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getMember(payload) {
        return await communicate.request(enums.GET, 'member/' + payload.id)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async setMember(payload) {
        return await communicate.request(enums.PATCH, 'member/' + payload.id, payload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getMemberChannels(payload) {
        return await communicate.request(enums.GET, 'memberchannels/' + payload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async setMemberChannel(payload) {
        return await communicate.request(enums.PATCH, 'memberchannel', payload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getMemberInterfaces(payload) {
        return await communicate.request(enums.GET, 'memberinterfaces/' + payload.memberid + '/' + payload.ssid)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async setMemberInterface(payload) {
        const method = payload.hasOwnProperty('ms_id') ? enums.PATCH : enums.PUT
        const path = 'memberinterface/' + payload.ms_memberid + '/' + payload.ms_ssid

        return await communicate.request(method, path, payload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async deleteMemberInterface(payload) {
        const path = 'memberinterface/' + payload.ms_memberid + '/' + payload.ms_ssid + '/' + payload.ms_id

        return await communicate.request(enums.DELETE, path, payload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getChannelProperties(payload) {
        return await communicate.request(enums.GET, 'getchannelproperties/' + payload.memberid + '/' + payload.ssid)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getClients(payload) {
        return await communicate.request(enums.GET, 'getclients/' + payload.memberid)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getMemberPolicies(payload) {
        return await communicate.request(enums.GET, 'getmemberpolicies/' + payload.memberid)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async setMemberPolicies(payload) {
        const data = payload.data
        const method = data.hasOwnProperty('id') ? enums.PATCH : enums.PUT
        const path = 'setmemberpolicies/' + payload.memberid

        const newPayload = {
            zahlplan_anzahlungbistagenachbuchung: data.zahlplan_anzahlungbistagenachbuchung,
            zahlplan_prozentanzahlung: data.zahlplan_prozentanzahlung,
            zahlplan_tagevoranreise: data.zahlplan_tagevoranreise,
            zahlplan_tagevoranreisefuerrestzahlung: data.zahlplan_tagevoranreisefuerrestzahlung,
        }

        if(data.hasOwnProperty('id'))
            newPayload.id = data.id

        return await communicate.request(method, path, newPayload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async checkConditionsAndTermsFile(payload) {
        return await communicate.request(enums.GET, 'checkconditionsandtermsfile', payload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async reportConditionsAndTerms(payload) {
        return await communicate.request(enums.POST, 'reportconditionsandterms', payload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async handleConditionsAndTerms(payload) {
        return await communicate.request(enums.POST, 'handleconditionsandterms', payload)
    },
}