import moment from 'moment'

/**
 *
 * @param date
 * @returns {string}
 */
function getFormattedDate (date) {
    return moment(date).format('MMMM Do[,] YYYY')
}

export default [
    {
        published: true,
        version: {
            major: '2',
            minor: '0',
            build: '0',
        },
        date: moment('2021-04-24').format('MMMM Do[,] YYYY'),
        title: 'Initial release',
        description: 'Feondi Admin V2 is an evolution of Feondi Admin V1 and is based on a newer technology that offers a higher level of functionality and multiple options than its predecessor.' +
            'Feondi users configure Feondi Admin V2 to be used as a back office of Feondi to view all data from Feondi.' +
            'Operations directly executable from the interface and visualization of self-configurable statistics.',
        image: 'logos/admincube.jpg',
        newFeatures: [],
        bugfixes: [],
        actions: {},
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '0',
            build: '1',
        },
        date: moment('2021-04-26').format('MMMM Do[,] YYYY'),
        title: 'Cookie support for better UX',
        description: 'Check the checkbox to enable all cookies. The admin learns your preferences and thus improves usability and can be developed for a better user experience.',
        image: 'aenderungsprotokoll/login-cookie.gif',
        newFeatures: [
            {
                ticketId: 'ADMINV2-11',
                title: 'Set cookies',
                description: 'Implement cookie support and save favorite settings in the app.',
            },
        ],
        bugfixes: [
            {
                ticketId: 'ADMINV2-3',
                title: 'Remove field for commission at website API',
                description: 'The field for commission at website API out, this channel only ICS calendar submitted and no prices.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '0',
            build: '2',
        },
        date: moment('2021-04-30').format('MMMM Do[,] YYYY'),
        title: 'Show changelog like this (😱 recursion!)',
        description: 'Shows all news that have been added since your last login. Small explanatory videos show you how to use the new feature.',
        image: 'aenderungsprotokoll/changelog-notif.gif',
        newFeatures: [
            {
                ticketId: '',
                title: 'Changelog',
                description: 'You can hide the news temporarily or until the new version, if you have set the hook "mark as read".',
            },
            {
                ticketId: '',
                title: 'Details in determine channels',
                description: 'Now you can see in the channels Tomas, Landsichten, Airbnb & Booking.com more specific details ' +
                    ' to get more informations about single account configuration in a member.',
            },
        ],
        actions: {
            example: (param) => {
                logger.log(`this is example function with param ${ param } in update with version ${ this.version.major }.${ this.version.minor }.${ this.version.build }, do something...`)
            },
        },
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '0',
            build: '4',
        },
        date: moment('2021-05-02').format('MMMM Do[,] YYYY'),
        title: 'Faster to use',
        description: 'Some improvements for usability and api connection for faster work. New features and some changes to serve a better performance.',
        image: 'aenderungsprotokoll/select-property.gif',
        bugfixes: [
            {
                ticketId: '',
                title: 'Create accounts',
                description: 'Channels that did not have an account may not have new accounts assigned to them.',
            },
        ],
        newFeatures: [
            {
                ticketId: '',
                title: 'Properties for members',
                description: 'Now you can also view properties for the member you are currently interacting as.',
            },
            {
                ticketId: '',
                title: 'Details in property for member',
                description: 'Select a property from the properties list and see the property\'s master data and other details.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Row click',
                description: 'Now you can click on the table row in the data table of the members overview to get to the contained details and actions.',
            },
            {
                ticketId: '',
                title: 'Row double click',
                description: 'Now you can go directly to the member in the data table of all members by a double click and you don\'t have to open the row first and then select details button.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '1',
            build: '0',
        },
        date: moment('2021-05-04').format('MMMM Do[,] YYYY'),
        title: 'Houses are in the house',
        description: 'Houses shortcuts to properties can now be displayed. Various bug fixes and more interaction with Feondi.',
        image: 'aenderungsprotokoll/house-select-objid.gif',
        bugfixes: [
            {
                ticketId: 'ADMINV2-17',
                title: 'Switched Validation type',
                description: 'The validation now takes place directly during the input and reports the warning until the value complies with the rule.',
            },
            {
                ticketId: 'ADMINV2-19',
                title: 'Interface could not be activated',
                description: 'For users who had not yet activated an interface, the first channel could not be activated under certain circumstances.',
            },
        ],
        newFeatures: [
            {
                ticketId: 'ADMINV2-20',
                title: 'Houses with properties',
                description: 'A new menu item behind which the member\'s houses are displayed. On the house details page the objects contained in the house are displayed and made selectable.',
            },
        ],
        improvements: [
            {
                ticketId: 'ADMINV2-16',
                title: 'Sync of prices',
                description: 'When changing the commission rate for channels that allow this setting, the prices will be updated to all channels.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '2',
            build: '0',
        },
        date: moment('2021-05-12').format('MMMM Do[,] YYYY'),
        title: 'Activities in realtime',
        description: 'This update allows changes made by other logged in users to be updated in real time on your screen.' +
            'Changes made by Fewo administrator users will also be changed on your screen if you are interacting as them.' +
            'If changes are made in the Fewo License Tool, the member will be updated in a roundabout way.',
        image: 'aenderungsprotokoll/member-update-socket.gif',
        bugfixes: [
            {
                ticketId: '',
                title: 'Password in interfaces',
                description: 'Under certain circumstances, the password was not saved in member interfaces.',
            },
            {
                ticketId: '',
                title: 'Commissions in interfaces',
                description: 'Under certain circumstances, the percentage markup in member interfaces was saved as 0.',
            },
        ],
        newFeatures: [],
        improvements: [
            {
                ticketId: '',
                title: 'Login',
                description: 'Better visibility when setting the session (2 h or 24 h).',
            },
            {
                ticketId: '',
                title: 'Performance',
                description: 'Improve performance and avoid unnecessary server requests.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '3',
            build: '0',
        },
        date: moment('2021-05-16').format('MMMM Do[,] YYYY'),
        title: 'Photos! Photos! Photos!',
        description: 'Photos available for properties and houses. More details such as recommendations for optimal resolution of the images and the actual resolution of the photos.',
        image: 'aenderungsprotokoll/photos.gif',
        bugfixes: [
            {
                ticketId: '',
                title: 'Google Chrome caching',
                description: 'Prohibit the browser Google Chrome from caching the page to avoid the need to manually clear the cache for updates. YOU NEED TO CLEAR YOUR CACHE ONE LAST TIME! 🤣',
            },
        ],
        newFeatures: [
            {
                ticketId: 'ADMINV2-19',
                title: 'Get photos',
                description: 'Get photos for properties and houses with detailed informations and recommondations',
            },
        ],
        improvements: [],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '3',
            build: '1',
        },
        date: moment('2021-06-14').format('MMMM Do[,] YYYY'),
        title: 'Improvements for more accurate information and bug fixes',
        description: 'For faster debugging and better use, some improvements have been made so that it is now even easier to work with the admin.',
        image: '',
        bugfixes: [
            {
                ticketId: 'ADMINV2-26',
                title: 'Multiple display of prices',
                description: 'Fixed an issue where prices were displayed as many times as there are configured languages to prices.',
            },
        ],
        newFeatures: [],
        improvements: [
            {
                ticketId: '',
                title: 'Payment schedule text',
                description: 'The text for the payment plan is now displayed live in the payment plan tab',
            },
            {
                ticketId: '',
                title: 'Fewo-Version',
                description: 'In the datatable of the members, the minor and build version number is now displayed in the version as well.',
            },
            {
                ticketId: '',
                title: 'Update contract',
                description: 'Shows in the datatable of all members if the customer has an update contract or not.',
            },
            {
                ticketId: '',
                title: 'Members filtered',
                description: 'Displays in the datatable of all members only the customers that are active in feondi.',
            },
        ],
    },
    /**
     * @this: Template to create a new changelog entry
     */
    {
        published: true,
        version: {
            major: '2',
            minor: '4',
            build: '1',
        },
        date: moment('2021-07-01').format('MMMM Do[,] YYYY'),
        title: 'Property services',
        description: 'Now you can also view the services of the property. The services are divided into additional and bookable services and with a click on the' +
            ' table row you can see the mappings to the connected master data portals.',
        image: 'aenderungsprotokoll/propertyservices.jpg',
        bugfixes: [
            {
                ticketId: 'ADMINV2-29',
                title: 'Filter by ID does not work',
                description: 'Wants to filter here by Id 58, nevertheless also shows all accommodations (not only those where 58 is in ID, title etc.).',
            },
        ],
        newFeatures: [
            {
                ticketId: 'ADMINV2-4',
                title: 'Get services',
                description: 'Get master data from Feondi and display it in the member.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Different colors in occupancies',
                description: 'Different colors of each occupancy, based on its type (B, SB, SL, ect.)',
            },
            {
                ticketId: 'ADMINV2-28',
                title: 'Better wording',
                description: 'Now wording "master data" is used instead of "legacy data".',
            },
            {
                ticketId: 'ADMINV2-24',
                title: 'Properties arrival and departure times',
                description: 'Properties arrival and departure times remove seconds and clock icon.',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '5',
            build: '0',
        },
        date: moment('2021-07-06').format('MMMM Do[,] YYYY'),
        title: 'Property distances',
        description: 'Now the distances and their mappings to the platforms are displayed under the distances tab.',
        image: 'aenderungsprotokoll/propertydistances.jpg',
        bugfixes: [
            {
                ticketId: '',
                title: 'Rows click expansion synchronization',
                description: 'Fixed an issue where clicking on a row in the Services could sometimes cause multiple rows to pop up.',
            },
        ],
        newFeatures: [
            {
                ticketId: 'ADMINV2-5',
                title: 'Get amenities',
                description: 'Get master data from Feondi and display it in the member. Amenities (Plus Mapping for Channel)',
            },
            {
                ticketId: 'ADMINV2-6',
                title: 'Get distances',
                description: 'Get master data from Feondi and display it in the member. Distances (Plus Mapping for Channel)',
            },
            {
                ticketId: 'ADMINV2-7',
                title: 'Get special offers',
                description: 'Get master data from Feondi and display it in the member.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Services live change',
                description: 'When services are changed in Feondi, the new data appears in the user interface in real time.',
            },
            {
                ticketId: '',
                title: 'Renaming "Master data" Tab',
                description: 'The "Master data" tabs have been renamed to "Basic data".',
            },
            {
                ticketId: '',
                title: 'All Feondi members',
                description: 'All Feondi members are now displayed in the members, except those who have died.',
            },
            {
                ticketId: '',
                title: 'Acting as member',
                description: 'To get from the overview of members to the individual member, you only need ' +
                    'a double click. The "act as member" button has been moved to the table row to allow faster selection. ' +
                    'The table row can no longer be expanded and the detail data has been moved to the individual member.',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '6',
            build: '0',
        },
        date: moment('2021-08-11').format('MMMM Do[,] YYYY'),
        title: 'Better proformance & usability',
        description: 'Changes in API and handling of loakl stored data was completely revised and perfected. ' +
            'Some new features and microfunctions make the work more pleasant and faster.',
        image: 'aenderungsprotokoll/commission-subchannel.jpg',
        bugfixes: [
            {
                ticketId: '',
                title: 'Local storage of data from the API',
                description: 'Received data was cleaned and minimized in the API, allowing for faster and more reliable ' +
                    'data transfer and rendering. The problem related to receiving large amounts of data from a request.',
            },
        ],
        newFeatures: [
            {
                ticketId: 'ADMINV2-6',
                title: 'Get special offers',
                description: 'Get master data of special offers from Feondi and display it in the member.',
            },
            {
                ticketId: 'ADMINV2-31',
                title: 'Reg-Nr. in Property',
                description: 'Get the property license number of a single property.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Acting as member in data table',
                description: 'The current acting member is displayed as a filter in the data table after navigating to ' +
                    'members. When you stop acting member, the filter is removed from the data table.',
            },
            {
                ticketId: '',
                title: 'Occupancies will be refreshed',
                description: 'When Feondi processes new tasks for a member that is currently acting member, they are ' +
                    'loaded live into the occupancy calendar.',
            },
            {
                ticketId: 'ADMINV2-25',
                title: 'Commission & Subchannel',
                description: 'Show commission and subchannel in interfaces datatable. Note: The column "Subchannel" is only in Feondi-API available!',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '6',
            build: '1',
        },
        date: moment('2021-08-27').format('MMMM Do[,] YYYY'),
        title: 'Optimizations, Improvements & Jobs BETA',
        description: 'Various typo fixes and clarity improvements for prices. Display of jobs from the Feondi queue in Beta.',
        image: 'aenderungsprotokoll/jobs-beta.jpg',
        bugfixes: [],
        newFeatures: [
            {
                ticketId: 'ADMINV2-33',
                title: 'Show prices from the past only on button toggle',
                description: 'The property prices tab now only shows prices that end after the current date. All previous ' +
                    'prices can be shown and hidden with a click on the button.',
            },
        ],
        improvements: [
            {
                ticketId: 'ADMINV2-32',
                title: 'Sort prices by from',
                description: 'Prices in the property prices tab are now displayed sorted by "from" in descending order.',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '6',
            build: '2',
        },
        date: moment('2021-09-14').format('MMMM Do[,] YYYY'),
        title: 'More info & clarifications',
        description: 'Color highlights & tooltips with more info to get more details. Now you can also learn more about ' +
            'the current status of individual properties assigned to channels.',
        image: 'aenderungsprotokoll/channel-properties.jpg',
        bugfixes: [],
        newFeatures: [
            {
                ticketId: 'ADMINV2-18',
                title: 'Get Statusreport',
                description: 'Get the status of an object assigned to a specific channel.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Channel Tabs',
                description: 'In a channel there are now more possibilities than before. Among others the interface and ' +
                    'properties assigned to the channel with status report.',
            },
            {
                ticketId: '',
                title: 'Member details',
                description: 'Within the member, the details of the booked Feondi options are now displayed.',
            },
            {
                ticketId: '',
                title: 'Search interface',
                description: 'In the interfaces tab you can now search for any value in the accounts. Also in new ' +
                    'channel properties.',
            },
            {
                ticketId: '',
                title: 'Better overview during sync',
                description: 'In the members, the members that are currently synced are now displayed in red and a ' +
                    'tooltip is displayed on the sync icon, since when the sync is already active.',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '7',
            build: '0',
        },
        date: moment('2021-09-15').format('MMMM Do[,] YYYY'),
        title: 'Sales potential & number of activated properties',
        description: 'To handle the sale of Feondi in a more targeted and efficient way, the potential of the members ' +
            'can now be seen in the members table. In the channel cards you can now see the number of properties ' +
            'activated for the channel.',
        image: 'aenderungsprotokoll/sales-potential-channelproperties-count.jpg',
        bugfixes: [
            {
                ticketId: '',
                title: 'Status in channel property',
                description: 'The statuses of properties within a channel may not have been updated when changing ' +
                    'channels or members.',
            },
        ],
        newFeatures: [
            {
                ticketId: '',
                title: 'Sales potential',
                description: 'In the members table three new values have been added. Number of properties, number of ' +
                    'registered properties and the potential that results from the two numbers, so that it is visible ' +
                    'at a glance how high the potential of a customer is.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Number of properties enabled for a channel',
                description: 'The channel cards now show the number of activated properties for the channel.',
            },
            {
                ticketId: '',
                title: 'Properties master data updated immediately',
                description: 'When changes are made in the fewo manager, all master data are now immediately loaded and ' +
                    'updated in the web interface, so that support can immediately see whether the expected changes have ' +
                    'been transferred as expected.',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '8',
            build: '0',
        },
        date: moment('2021-10-05').format('MMMM Do[,] YYYY'),
        title: 'Authentication security & more details in data',
        description: 'The login is now equipped with a Google reCaptcha V3 and checks whether the requester is actually ' +
            'a human. Other improvements in the display of data.',
        image: '',
        bugfixes: [],
        newFeatures: [],
        improvements: [
            {
                ticketId: 'ADMINV2-35',
                title: 'More details in price data table',
                description: 'In the data table for properties prices, the arrival days are now displayed so that if ' +
                    'the arrival is always possible or on a specific day, this is displayed. If several arrival days are ' +
                    'possible, an info icon is displayed that shows a tooltip with all possible arrival days when hovering ' +
                    'with the mouse.',
            },
            {
                ticketId: '',
                title: 'Different colors in occupancies (V2)',
                description: 'The colors in the calendar for events and online bookings have been adjusted so that the ' +
                    'darker version of the same color has been replaced by another to make the difference more noticeable.',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '9',
            build: '0',
        },
        date: moment('2021-11-12').format('MMMM Do[,] YYYY'),
        title: 'Super Duper Job-Creator',
        description: 'A super duper tool to create jobs for the queue for members subscribed to the selected channel. ' +
            'Choose from several members, their objects and the job you want to run. Another menu item within the queue ' +
            'are the squential jobs, which Feondi creates and processes via a cron not immediately, but in certain cycles. ' +
            'These cycles can also be found in the datatable of the cron queue jobs.',
        image: 'aenderungsprotokoll/job-creator.jpg',
        bugfixes: [],
        newFeatures: [
            {
                ticketId: '',
                title: 'Job-Creator',
                description: 'create jobs for the queue for members subscribed to the selected channel. ' +
                    'Choose from several members, their objects and the job you want to run.',
            },
            {
                ticketId: '',
                title: 'Cron  jobs',
                description: 'A new menu item within the queue are the cron queued jobs, which Feondi creates and ' +
                    'processes via a cron not immediately, but in certain cycles. These cycles can also be found in ' +
                    'the datatable of the cron queued jobs.',
            },
            {
                ticketId: '',
                title: 'Channel properties',
                description: 'Now the properties that are activated in a certain channel are displayed with their status ' +
                    'under Member -> Channel -> Channel-Properties.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Rows per page',
                description: 'In each datatable, the setting of the number of rows of the dataset is now adjustable. ' +
                    'You can display 25, 50, 100 or all rows in one dataset.',
            },
        ],
    },

    /**
     * @this: Template to create a new changelog entry
     */
    {
        published: true,
        version: {
            major: '2',
            minor: '9',
            build: '1',
        },
        date: moment('2021-11-15').format('MMMM Do[,] YYYY'),
        title: 'Super Duper Job-Creator Fix',
        description: '',
        image: '',
        bugfixes: [
            {
                ticketId: '',
                title: 'Key navigation in the Job Creator',
                description: 'Fixed an issue that created empty elements in the member and properties search and thus ' +
                    'invalidated the API requests. It is now possible to create entire job sets using only key navigation.',
            },
        ],
        newFeatures: [],
        improvements: [],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '10',
            build: '0',
        },
        date: moment('2021-12-04').format('MMMM Do[,] YYYY'),
        title: 'Feondi booking engine 🔥',
        description: 'Many minor improvements, performance enhancements and new features. With the new implementation of' +
            ' the Google, FBE (Feondi booking engine) and Feondi Travel channels, advanced channel settings can now be made.',
        image: 'aenderungsprotokoll/check-terms-and-conditions.jpg',
        bugfixes: [],
        newFeatures: [
            {
                ticketId: 'ADMINV2-39',
                title: 'Checkbox: Add "All properties"',
                description: 'In the job creator, all properties can now be selected at once by clicking on a checkbox.',
            },
            {
                ticketId: '',
                title: 'Terms & Conditions in interface FBE',
                description: 'It is now possible to object or confirm the T&Cs uploaded by the administrator. Without' +
                    ' this confirmation no properties will be transferred to Feondi Travel and Google!',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Responsive design',
                description: 'More responsive compatibility throughout the project',
            },
            {
                ticketId: 'ADMINV2-38',
                title: 'Memberid in headings',
                description: 'Under Channels and in the Channel insert the Member ID',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '11',
            build: '0',
        },
        date: moment('2022-01-02').format('MMMM Do[,] YYYY'),
        title: 'Sturctured UI',
        description: 'The structure in the datatables were improved by separators. Bugfixes and small improvements.',
        image: 'aenderungsprotokoll/contextmenu-members.jpg',
        bugfixes: [
            {
                ticketId: 'ADMINV2-40',
                title: 'Create new interface',
                description: 'It was not possible to create a new interface per channel. Instead, the basic data of the' +
                    ' member was displayed.',
            },
        ],
        newFeatures: [
            {
                ticketId: '',
                title: 'Act as member',
                description: 'The act as member function can now also be triggered in the member itself.',
            },
            {
                ticketId: '',
                title: 'Context menu in members',
                description: 'The action "act as member" can be executed by right-clicking on a row in the datatable of' +
                    ' all members. It is also possible to navigate into the member via the menu.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Data structure in datatables',
                description: 'The structure in the datatables now looks tidier than before due to separator lines.',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '11',
            build: '1',
        },
        date: moment('2022-01-05').format('MMMM Do[,] YYYY'),
        title: 'Channels',
        description: 'Channels that are not member dependent can now be viewed and configured.',
        image: '',
        bugfixes: [
            {
                ticketId: '',
                title: 'Deleting interfaces',
                description: 'Fixed a problem that occurred when deleting member interfaces and gave a wrong message.',
            },
        ],
        newFeatures: [],
        improvements: [],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '12',
            build: '0',
        },
        date: moment('2022-02-06').format('MMMM Do[,] YYYY'),
        title: 'Special values of channels',
        description: 'Now the special values of the individual channels like e.g. hotel or room IDs are displayed.' +
            ' Channel-specific actions are available for some channels. There were also changes made to the layout.' +
            ' Datatables searches have been improved so that only relevant data is searched, making the results more accurate.',
        image: 'aenderungsprotokoll/search-by-hotelid.jpg',
        bugfixes: [],
        newFeatures: [
            {
                ticketId: 'ADMINV2-41',
                title: 'Channel Properties special values',
                description: 'Display the mapping codes from OBJE and if necessary OBJ2MS per channel.',
            },
            {
                ticketId: 'ADMINV2-44',
                title: 'Search for hotelid in job creator',
                description: 'Property search in the job creator for the channel specific property ID',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Search in datatables',
                description: 'Datatables searches have been improved so that only relevant data is searched, making the' +
                    ' results more accurate.',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '12',
            build: '2',
        },
        date: moment('2022-02-08').format('MMMM Do[,] YYYY'),
        title: 'Better UI for activated/deactivated states',
        description: 'Now you can see better in datatables if an option is set or not. For values that require a mapping,' +
            ' the mapping code or nothing is now displayed.',
        image: '',
        bugfixes: [],
        newFeatures: [
            {
                ticketId: 'ADMINV2-46',
                title: 'Show Feondi assignment directly in list',
                description: 'It would be cool if the Feondi assignment to' +
                    '<ul><li>Equipment</li>' +
                    '<li>Distances</li>' +
                    '<li>Services</li></ul>' +
                    'would be shown directly in the table as a column',
            },
            {
                ticketId: 'ADMINV2-45',
                title: 'Clearer representation than points',
                description: 'The red and green dots are not clear, red dots indicate danger. A clearer representation,' +
                    ' for example with checkboxes, would be great.',
            },
        ],
        improvements: [],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '12',
            build: '3',
        },
        date: moment('2022-02-10').format('MMMM Do[,] YYYY'),
        title: 'Clients & Menu',
        description: 'Another menu item Clients was added and the main menu was redesigned',
        image: 'aenderungsprotokoll/clients+menu.jpg',
        bugfixes: [
            {
                ticketId: '',
                title: 'Airbnb link',
                description: 'Fixed an issue that sometimes made the link to the Airbnb listing unusable. The link can' +
                    ' be opened in a new tab by holding down the CTRL key and clicking.',
            },
        ],
        newFeatures: [
            {
                ticketId: '',
                title: 'Clients',
                description: 'The clients of the member can now be displayed. There is a new menu item in the main menu' +
                    ' for this purpose.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Feondi and own title',
                description: 'In feats, distances and equipment, the fixed feondi and the item\'s own title are now' +
                    ' displayed. This allows a faster recognition of the mapping.',
            },
            {
                ticketId: '',
                title: 'FBE & Google activation',
                description: 'Fixed an issue that occurred for reactivity when activating or deactivating member' +
                    ' channels. If FBE is not enabled, Google cannot be enabled. If the member has no clients, Google' +
                    ' cannot be activated.',
            },
            {
                ticketId: '',
                title: 'Confirmation of the submitted GTC',
                description: 'T&Cs cannot be confirmed if Google is not enabled or if the member has no clients. If the' +
                    ' two required points are met, the T&Cs can be confirmed.',
            },
        ],
    },

    {
        published: true,
        version: {
            major: '2',
            minor: '13',
            build: '0',
        },
        date: getFormattedDate('2022-02-22'),
        title: 'Languages, customer requests and optimizations',
        description: 'In the entire application it is now possible to click on its name to get to a menu where you can' +
            ' switch the language of the app between German and English. Improvements have been made to the ActAsMember' +
            ' function so that you no longer have to call this function separately. The tables have a new format and' +
            ' some suggested improvements have been implemented.',
        image: 'aenderungsprotokoll/languages.jpg',
        bugfixes: [
            {
                ticketId: '',
                title: 'Act as member',
                description: 'Fixed an issue that caused the ActAsMember function to not reliably load all member specific data.',
            },
            {
                ticketId: '',
                title: 'Channel-Properies fetch',
                description: 'Fixed an issue that was responsible for incorrect loading of channel properties.',
            },
            {
                ticketId: '',
                title: 'Google activation',
                description: 'Fixed an issue that was responsible for incorrect display of a message that the Google' +
                    ' channel cannot be activated.',
            },
        ],
        newFeatures: [
            {
                ticketId: '',
                title: 'Switch languages',
                description: 'It is now possible to switch the display language between German and English.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Simplified  acting as member',
                description: 'By simply clicking on a member in the members table, we will automatically act as that' +
                    ' member. All further actions are then executed as this member. Within the menu item "member" the' +
                    ' acting cannot be terminated. Under all other menu items the acting can be stopped. When navigating' +
                    ' back to all members, the action of the previous member is automatically ended.',
            },
            {
                ticketId: 'ADMINV2-47',
                title: 'Clearer representation than points',
                description: 'The red and green dots are not clear, red dots indicate danger. A clearer representation,' +
                    ' for example with radios, would be great.',
            },
            {
                ticketId: 'ADMINV2-48',
                title: 'Revise the representation of the surcharges',
                description: 'Added more details for surcharges.',
            },
            {
                ticketId: 'ADMINV2-52',
                title: 'Set focus',
                description: 'It would be cool if the focus was directly in the search field when you open these two' +
                    'areas - then you don\'t have to click in there separately with the mouse',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '14',
            build: '0',
        },
        date: getFormattedDate('2022-06-29'),
        title: 'Delete jobs & new job types',
        description: 'Jobs can be removed from the queue and Booking.com has added a new job "sendClients".',
        image: 'aenderungsprotokoll/delete-job.jpg',
        bugfixes: [],
        newFeatures: [],
        improvements: [
            {
                ticketId: '',
                title: 'Delete jobs',
                description: 'As a admin user you now have the possibility to delete a specific job from the queue.',
            },
            {
                ticketId: '',
                title: 'New job for Booking.com',
                description: 'You can now trigger a job "sendClients" for Booking.com to send the properties profile.' +
                    ' It will send the "About us" section from the client (monolingual), the object description (multilingual)' +
                    ' and the company logo from the client to Booking.com.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '15',
            build: '0',
        },
        date: getFormattedDate('2022-07-11'),
        title: 'Reset jobs',
        description: 'Jobs can be reset in the queue.',
        image: 'aenderungsprotokoll/reset-job.jpg',
        bugfixes: [],
        newFeatures: [],
        improvements: [
            {
                ticketId: '',
                title: 'Reset jobs',
                description: 'As a admin user you now have the possibility to reset a specific job in the queue.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '16',
            build: '0',
        },
        date: getFormattedDate('2022-07-12'),
        title: 'Rooms in property & improvements',
        description: 'Representation of the room configuration with the associated amenities of the property.',
        image: 'aenderungsprotokoll/rooms-tab.jpg',
        bugfixes: [],
        newFeatures: [],
        improvements: [
            {
                ticketId: '',
                title: 'Member sync in current jobs',
                description: 'Fixed a problem that in the active job the member was still displayed as synchronizing' +
                    ' when the synchronization was already completed. The current status of the synchronization now' +
                    ' appears next to the id of the member.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '17',
            build: '0',
        },
        date: getFormattedDate('2022-07-25'),
        title: 'Various fixes and improvements',
        description: 'A successful ride through Jira yielded many suggested improvements.',
        image: '',
        bugfixes: [
            {
                ticketId: 'ADMINV2-59',
                title: 'Typo',
                description: 'Oopps 🙄',
            },
            {
                ticketId: 'ADMINV2-60',
                title: 'Feratel headings swapped in table',
                description: 'The tables heading in the Feratel channel had an incorrect assignment. The values of the' +
                    ' Hotel & Room ID as well as the INV code have now been assigned correctly.',
            },
            {
                ticketId: 'ADMINV2-56',
                title: 'Types and kinds of special offers',
                description: 'The assignment of the type of special offers was incorrect in that the type was missing' +
                    ' and therefore the content of the offer was displayed incorrectly. The type and the kind are now' +
                    ' multilingual displayed correctly in text form.',
            },
            {
                ticketId: 'ADMINV2-58',
                title: 'Wrong name for booking status',
                description: 'The correct designation for the posting status SB has now been set.',
            },
        ],
        newFeatures: [
            {
                ticketId: 'ADMINV2-45',
                title: 'It would be cool if clicking on the object here would open a new window',
                description: 'By holding down the CTRL/CTL key and clicking on an accommodation in the channel properties,' +
                    ' a new tab with the selected property is opened.',
            },
            {
                ticketId: '',
                title: 'Airbnb link to diagnostics',
                description: 'The link in channel properties to the old Airbnb XML file has now been changed to the link' +
                    ' to the Airbnb partner portal and can be accessed by holding down the CTRL/CTL key.',
            },
        ],
        improvements: [
            {
                ticketId: 'ADMINV2-57',
                title: 'If only one digit is entered the object title will not be searched',
                description: 'In the search it is now possible to set whether only the object ID or the entire text should' +
                    ' be searched when entering numbers.',
            },
            {
                ticketId: 'ADMINV2-55',
                title: 'Assign passwords even as admin',
                description: 'Now also a user with the role as admin can assign and/or change passwords for other users.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '18',
            build: '0',
        },
        date: getFormattedDate('2022-11-27'),
        title: 'Some improvements and options',
        description: 'We have implemented some improvements and various options to make examining Feondi data easier.',
        image: 'aenderungsprotokoll/photoCacheClear.jpg',
        bugfixes: [],
        newFeatures: [
            {
                ticketId: '',
                title: 'Photos cache clear bei jobCreation',
                description: 'It is now possible to clear the Feondi photo cache on channels where photos are cached and on the "sendPhotos" job.',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: 'Channel name in job creation',
                description: 'In the flow when creating jobs, the currently selected channel is continuously visible in text form.',
            },
            {
                ticketId: '',
                title: 'Airbnb services mapping',
                description: 'The services gamed to Airbnb have been added to the Accordeon overview.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '19',
            build: '0',
        },
        date: getFormattedDate('2022-02-19'),
        title: 'Features for faster work',
        description: 'Subchannels are sorted alphabetically in the dorp down and access data can be copied.',
        image: 'aenderungsprotokoll/copy-credentials.gif',
        bugfixes: [],
        newFeatures: [
            {
                ticketId: '',
                title: 'Change customer data in LicGen',
                description: 'If customer data is changed in LicGen and you are in a customer -> channel, it will be' +
                    ' updated by an extended socket event.',
            },
        ],
        improvements: [
            {
                ticketId: 'ADMINV2-68',
                title: 'Subchannels sorting',
                description: 'The selectable subchannels are sorted alphabetically in a Customer -> Channels -> Feondi' +
                    ' API -> Interface.',
            },
            {
                ticketId: '',
                title: 'Copy the API credentials',
                description: 'The access data for a subchannel can now be copied in Customer -> Channels -> Feondi API' +
                    ' ->Interface language-dependent.',
            },
        ],
    },
    {
        published: true,
        version: {
            major: '2',
            minor: '20',
            build: '0',
        },
        date: getFormattedDate('2023-11-10'),
        title: 'Discounts in properties master data',
        description: 'The discounts assigned to the accommodation can now also be viewed in the properties master data.',
        image: 'aenderungsprotokoll/property-discounts.jpg',
        newFeatures: [
            {
                ticketId: '',
                title: 'Discounts',
                description: 'Discounts per assigned property in property master data',
            },
        ],
    },

    /**
     * Template to create a new changelog entry
     */
    {
        published: false,
        version: {
            major: '2',
            minor: 'x',
            build: 'x',
        },
        date: getFormattedDate('2022-02-12'),
        title: '',
        description: '',
        image: '',
        bugfixes: [
            {
                ticketId: '',
                title: '',
                description: '',
            },
        ],
        newFeatures: [
            {
                ticketId: '',
                title: '',
                description: '',
            },
        ],
        improvements: [
            {
                ticketId: '',
                title: '',
                description: '',
            },
        ],
    },
]