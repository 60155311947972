export default {

    /**
     * 
     * @param state
     * @returns {string}
     */
    getToken: state => {
        return state.token
    },

    /**
     *
     * @param state
     * @returns {boolean}
     */
    isAuthenticated: state => {
        return state.isAuthenticated
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getUser: state => {
        return state.user
    },

    /**
     *
     * @param state
     * @returns {{firstname : string, initials : string, company : string, fullname : string, lastname : string}}
     */
    getUsernameTexts: state => {
        return state.userNameTexts
    },
}