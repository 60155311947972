export default [
    {
        path: '/clients',
        name: 'Clients',
        meta: {
            requiresAuth: true,
            breadcrumb: true,
            menuGroup: 'clients',
        },
        component: () => import('@/views/Mandanten.vue'),
    },
]