import axios from 'axios'
import store from '../store/index'
import Vue from 'vue'

let task = {}

export default {
    /**
     *
     * @param method
     * @param path
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async request(method, path, payload = {}) {
        try {
            task.taskId = `[${ Date.now() }]`
            task.appModule = 'adminv2'
            task.request = {
                method,
            }

            const storedTask = store.getters.getTask

            if (storedTask.hasOwnProperty('agent'))
                task.agent = storedTask.agent

            if (storedTask.hasOwnProperty('agentId'))
                task.agentId = storedTask.agentId

            const data = {
                payload: payload,
                task: task,
            }

            logger.log(`this is postData ${ method } ${ path }:`, data)

            //Info: Use POST method for each request - see info above
            const response = await axios.post(enums.API_URL + path, data)

            logger.log(`this is response ${ method } ${ path }:`, response)

            Vue.$cookies.set('lastTransfer', Date.now())

            return this.response(response, path)
        }
        catch(e) {

            logger.log(`this is error ${ method } ${ path }:`, e)

            return this.response(e, path)
        }
    },
    /**
     *
     * @param values
     * @param path
     * @returns {{body}|*|{warning}}
     */
    async response(values, path) {

        logger.log(`this is response data in ${ path } response handler: `, values)

        //Info: Update the task at this point with the properties of the server (api) operations
        if (values && values.hasOwnProperty('data') && values.data.hasOwnProperty('task')) {
            task = values.data.task
            store.commit('SET_TASK', task)
        }

        const data = values.data
        const statusCode = values.status

        if (data && statusCode === 200 && data.hasOwnProperty('body'))
            return data.body
        else if (data && data.hasOwnProperty('warning')) {
            if (data.warning.msg.toLowerCase().includes('token'))
                await store.dispatch('logout')
            else {
                store.commit('SET_ALERT', { type: 'error', message: data.warning.msg })

                store.commit('SET_BANNER', {
                    type: 'warning',
                    message: data.warning.msg,
                    icon: 'mdi-alert-outline',
                    btnText: 'Ok',
                    action: false,
                })
            }
        }
        else if (values && values.constructor.name === 'Error') {

            logger.log(`this is errors data in error handler: `, JSON.parse(JSON.stringify(values)))

            store.commit('SET_BANNER', {
                type: 'error',
                message: values.message,
                icon: 'mdi-alert-circle-outline',
                btnText: 'Ok',
                action: false,
            })
        }

        return null
    },
}