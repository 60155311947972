export default {
    SET_SUBCHANNELS: ((state, payload) => {
        state.subChannels = payload
    }),
    SET_PROPERTY_CHANNELS: ((state, payload) => {
        state.propertyChannels = payload
    }),
    SET_PROPERTY_CHANNEL_MEMBERS: ((state, payload) => {
        state.propertyChannelMembers = payload
    }),
    SET_CHANNEL_MEMBERS: ((state, payload) => {
        state.channelMembers = payload
    }),
    RESET_CHANNEL_MEMBERS: (state => {
        state.channelMembers.splice(0, state.channelMembers.length)
    }),
}