import communicate from '@/services/communicate.service'

export default {
    /**
     *
     * @returns {Promise<*|*[]>}
     */
    async getChannels() {
        return await communicate.request(enums.GET, 'channels')
    },

    /**
     *
     * @returns {Promise<*|*[]>}
     */
    async getSubChannels() {
        return await communicate.request(enums.GET, 'subchannels')
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async setChannel(payload) {
        return await communicate.request(enums.PATCH, 'channel', payload)
    },

    /**
     *
     * @param payload
     * @return {Promise<*|{warning}|{body}>}
     */
    async getChannel(payload) {
        return await communicate.request(enums.GET, 'channel/' + payload.ssid)
    },

    /**
     *
     * @returns {Promise<*|*[]>}
     */
    async getPropertyChannels() {
        return await communicate.request(enums.GET, 'getpropertychannels')
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyChannelMembers(payload) {
        return await communicate.request(enums.GET, 'channelpropertymembers/' + payload.ssid)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getChannelPropertiesMultipleMembers(payload) {
        return await communicate.request(enums.POST, 'getchannelpropertiesmultiplemembers/' + payload.ssid, payload)
    },
}