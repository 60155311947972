export default {
    /**
     *
     * @param data
     * @description another user has made a change in the app
     * @returns {Promise<void>}
     */
    async memberUpdate(data) {
        if (this.$store.getters.isAuthenticated && this.$store.getters.getMembers.length) {
            await this.$store.dispatch('getAndSetMembers', true)
            await this.$store.dispatch('socketEventBanner', { message: `${ data.user.fullname } changed member data!`, autoHide: true })
        }
    },

    /**
     *
     * @param data
     * @description another user has made a change in the app, receiving via broadcastEmitter (sender->index.js)
     * @returns {Promise<void>}
     */
    async interfaceUpdate(data) {

        if (utils.checkSocketRequestedMemberidFromRoute(this, data))
            await this.$store.dispatch('getAndSetInterfaces', data)

        if (this.$store.getters.isAuthenticated)
            await this.$store.dispatch('socketEventBanner', { message: `${ data.user.fullname } changed some interface data!`, autoHide: true })
    },

    /**
     *
     * @param data
     * @description another user has made a change in the app
     * @returns {Promise<void>}
     */
    async memberPoliciesUpdate(data) {

        if (utils.checkSocketRequestedMemberidFromRoute(this, data))
            await this.$store.dispatch('getAndSetPolicies', data)

        if (this.$store.getters.isAuthenticated)
            await this.$store.dispatch('socketEventBanner', { message: `${ data.user.fullname } changed some member policies data!`, autoHide: true })
    },

    /**
     *
     * @param data
     * @description another user has made a change in the app, receiving via broadcastEmitter (sender->index.js)
     * @returns {Promise<void>}
     */
    async memberChannelUpdate(data) {

        if (utils.checkSocketRequestedMemberidFromRoute(this, data))
            await this.$store.dispatch('getAndSetMemberChannels', data)

        if (this.$store.getters.isAuthenticated)
            await this.$store.dispatch('socketEventBanner', { message: `${ data.user.fullname } changed some member channel data!`, autoHide: true })
    },
}