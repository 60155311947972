<template>
    <v-dialog
        v-model="dialog"
        width="380"
        :overlay-color="getColors.primary"
        @click:outside="dialog = false"
    >
        <v-card>
            <v-card-title class="headline lighten-2 break-spaces">
                {{ data.title }}
            </v-card-title>
            <v-card-text v-html="data.text"/>
            <v-divider/>
            <v-card-actions>
                <v-btn
                    :color="getColors.secondary1"
                    text
                    @click="cancelAction"
                >
                    {{ data.hasOwnProperty('cancelBtnText') && data.cancelBtnText ? data.cancelBtnText : $t('common.words.cancel') }}
                </v-btn>
                <v-spacer/>
                <v-btn
                    v-if="data.hasOwnProperty('replyAction') && data.replyAction.state && !getLoaders[0]"
                    :color="getColors.error"
                    text
                    @click="$emit('replyAction', getDialog.data)"
                >
                    {{ data.replyAction.btnText }}
                </v-btn>
                <v-progress-circular
                    v-else-if="data.hasOwnProperty('replyAction') && getLoaders[0] && data.replyAction.state"
                    indeterminate
                    :color="getColors.secondary1"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Dialog',
    props: {
        data: Object
    },
    computed: {
        ...mapGetters([
            'getDialog',
            'getColors',
            'getLoaders',
        ]),
        dialog: {
            get() {
                return this.$store.getters.getDialog.state || false
            },
            set(newVal) {

                logger.log(`this is newVal in cancel dialog: `, newVal)

                if (!newVal)
                    this.$store.dispatch('resetProps', 'dialog')
            },
        },
    },
    methods: {
        cancelAction() {
            this.dialog = false
            this.$emit('cancelAction', this.getDialog.data)
        },
    },
}
</script>
