export default {
    UPDATE_JOB_CREATION: ((state, payload) => {
        if (payload.hasOwnProperty('ssid') && payload.ssid > 0)
            state.jobCreation.ssid = payload.ssid

        if (payload.hasOwnProperty('textid') && payload.textid !== '')
            state.jobCreation.textid = payload.textid

        if (payload.hasOwnProperty('channelName') && payload.textid !== '')
            state.jobCreation.channelName = payload.channelName

        if (payload.hasOwnProperty('members'))
            state.jobCreation.members = payload.members

        if (payload.hasOwnProperty('memberid') && payload.hasOwnProperty('properties'))
            state.jobCreation.members[payload.memberid].properties = payload.properties

        if (payload.hasOwnProperty('job') && payload.job !== '')
            state.jobCreation.job = payload.job

        if (payload.hasOwnProperty('photosCacheClear'))
            state.jobCreation.photosCacheClear = payload.photosCacheClear

        if (payload.hasOwnProperty('success') && Object.keys(payload.success).length)
            state.jobCreation.success = payload.success

        if (payload.hasOwnProperty('redirect'))
            state.jobCreation.redirect = payload.redirect
    }),
}