export default [
    {
        path: '/members',
        name: 'Members',
        meta: {
            requiresAuth: true,
            breadcrumb: true,
            menuGroup: 'members'
        },
        component: () => import('@/views/Kunden.vue'),
    },
    {
        path: '/member/:memberid',
        name: 'Member',
        props: true,
        meta: {
            requiresAuth: true,
            requiresMembers: false,
            breadcrumb: true,
            hasForm: true,
            menuGroup: 'members'
        },
        component: () => import('@/views/Kunde.vue'),
    },
    {
        path: '/member/:memberid/member-channel/:ssid',
        name: 'Member-Channel',
        props: true,
        meta: {
            requiresAuth: true,
            requiresMembers: true,
            requiresMember: true,
            requiresMemberChannels: true,
            breadcrumb: true,
            menuGroup: 'members',
        },
        component: () => import('@/components/Kunde/Schnittstelle.vue'),
    },
    {
        path: '/member/:memberid/member-channel/:ssid/interface/:msid?/:agb?/:s?',
        name: 'Interface',
        props: true,
        meta: {
            requiresAuth: true,
            requiresMembers: true,
            requiresMember: true,
            requiresMemberChannel: true,
            breadcrumb: true,
            hasForm: true,
            menuGroup: 'members',
        },
        component: () => import('@/components/Kunde/Schnittstellenverknuepfung.vue'),
    },
]