import Vue from 'vue'
import Vuex from 'vuex'
import createPersitedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ encodingType: 'des', isCompression: false, encryptionSecret: 'my-secret-key-1' })

Vue.use(Vuex)

import defaultState from '@/store/defaultState'
import getters from '@/store/getters'
import mutations from '@/store/mutations'
import actions from '@/store/actions'

export default new Vuex.Store({
    strict: process.env.VUE_APP_ENV !== 'production',
    plugins: [
        createPersitedState(),
    ],
    state: defaultState,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {},
})