export default {
    /**
     *
     * @param data
     */
    socketConnection(data) {
        this.$store.commit('SET_SOCKETCONNECTION', data)
        this.$store.commit('SET_COLORS', {primary: data ? '#34495e' : '#c0392b'})
    },

    /**
     *
     * @param data
     */
    cacheClearForAll(data) {
        this.$store.commit('SET_BANNER', {
            type: 'warning',
            message: 'New content is available and Admin must be reloaded!',
            icon: 'mdi-check-all',
            btnText: 'Not now',
            actionBtnText: 'Reload',
            action: async () => {
                await this.$store.dispatch('cacheClear', {routing: false, reload: true})
            },
        })
    },

    /**
     *
     * @param data
     */
    feondiSync(data) {
        if (this.$store.getters.isAuthenticated) {
            //Info: Is fired at each send all data beginning (status 0) and end (status 1)
            this.$store.commit('SOCKET_MEMBER_SYNC', {
                id: data.memberid,
                status: data.status,
            })
        }
    },

    /**
     *
     * @param data
     * @description the support made changes in licgen
     * @returns {Promise<void>}
     */
    async licgenUpdate(data) {
        if (this.$store.getters.isAuthenticated && this.$store.getters.getMembers.length) {
            await this.$store.dispatch('getAndSetMembers', true)

            if (this.$store.getters.isAuthenticated && Object.keys(this.$store.getters.getMember).length && this.$store.getters.getMember.id && this.$route.name.toLowerCase() === 'member')
                await this.$store.dispatch('getAndSetMemberChannels', {force: true, memberid: this.$store.getters.getMember.id})

            await this.$store.dispatch('socketEventBanner', {message: `Some member data changed by Fewo-Licgen Software!`, autoHide: true})
        }
    },

    /**
     *
     * @param data
     * @returns {Promise<void>}
     */
    async basicDataUpdate(data) {
        const memberid = data.memberid

        if (this.$store.getters.isAuthenticated && Object.keys(this.$store.getters.getMember).length && this.$store.getters.getMember.id === memberid && Object.keys(this.$store.getters.getPolicies).length) {
            await this.$store.dispatch('getAndSetPolicies', {
                memberid: memberid,
                force: true,
            })

            await this.$store.dispatch('socketEventBanner', {message: `Member changed basic data!`, autoHide: true})
        }

        if (utils.checkSocketRequestedMemberidFromStore(this, data) && this.$store.getters.getClients.length) {
            await this.$store.dispatch('getAndSetClients', {force: true, memberid: memberid})
            await this.$store.dispatch('socketEventBanner', {message: `Some clients changed for member ${ memberid }!`, autoHide: true})

            if (this.$store.getters.getMemberChannels.length)
                await this.$store.dispatch('getAndSetMemberChannels', {force: true, memberid: memberid})
        }
    },

    /**
     *
     * @param data
     * @returns {Promise<void>}
     */
    async exportUpdate(data) {
        if (utils.checkSocketRequestedMemberidFromRoute(this, data)) {
            const memberid = parseInt(data.memberid)
            const routeName = this.$route.name.toLowerCase()
            const tab = this.$store.getters.getTab
            const payload = {
                memberid,
                force: true,
            }

            if (routeName === 'member' && tab === 1)
                await this.$store.dispatch('getAndSetMemberChannels', payload)

            if (routeName === 'member-channel' && tab === 0)
                await this.$store.dispatch('getAndSetInterfaces', payload)
        }
    },

    /**
     *
     * @param data
     * @returns {Promise<void>}
     */
    async handledPropertyJob(data) {
        const ssid = parseInt(this.$route.params.ssid) || 0

        if (ssid === data.ssid && utils.checkSocketRequestedMemberidFromRoute(this, data) && this.$route.name.toLowerCase() === 'member-channel' && this.$store.getters.getTab === 1) {
            await this.$store.dispatch('getAndSetChannelProperties', {
                memberid: parseInt(data.memberid),
                force: true,
            })
        }
    },

    /**
     *
     * @param data
     * @returns {Promise<void>}
     */
    async clientsUpdate(data) {
        if (utils.checkSocketRequestedMemberidFromStore(this, data) && this.$store.getters.getClients.length) {
            await this.$store.dispatch('getAndSetClients', true)
            await this.$store.dispatch('socketEventBanner', {message: `Some clients changed for member ${ data.memberid }!`, autoHide: true})
        }
    },
}