export default {
    /**
     *
     * @param state
     * @returns {{}}
     */
    getTask: state => {
        return state.task
    },

    /**
     *
     * @param state
     * @returns {boolean}
     */
    getSocketConnection: state => {
        return state.socketConnection
    },

    /**
     *
     * @param state
     * @returns {{secondary1 : string, success : string, secondary2 : string, warning : string, error : string, primary : string}}
     */
    getColors: state => {
        return state.colors
    },

    /**
     *
     * @param state
     * @returns {{type : string, message : string}}
     */
    getAlert: state => {
        return state.alert
    },

    /**
     *
     * @param state
     * @returns {{icon : string, action : null, actionBtnText : string, type : string, message : string, closeBtnText : string}}
     */
    getBanner: state => {
        return state.banner
    },

    /**
     *
     * @param state
     * @returns {[]}
     */
    getBreadcrumbs: state => {
        return state.breadcrumbs
    },

    /**
     *
     * @param state
     * @returns {[]}
     */
    getModels: state => {
        return state.models
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getForm: state => {
        return state.form
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getChips: state => {
        return state.chips
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getLoaders: state => {
        return state.loaders
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getDisables: state => {
        return state.disables
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getMessages: state => {
        return state.messages
    },

    /**
     *
     * @param state
     * @returns {{}}
     */
    getKey: state => {
        return state.key
    },

    /**
     *
     * @param state
     * @returns {number}
     */
    getTab: state => {
        return state.tab
    },

    /**
     *
     * @param state
     * @returns {number}
     */
    getStep: state => {
        return state.step
    },

    /**
     * 
     * @param state
     * @returns {{data : {}, state : boolean}}
     */
    getDialog: state => {
        return state.dialog
    },

    /**
     *
     * @param state
     * @returns {{fullPath : string, params : {}}}
     */
    getRouting: state => {
        return state.routing
    },

    /**
     *
     * @param state
     * @returns {string}
     */
    getLocale: state => {
        return state.locale
    },
}