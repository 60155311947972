import io from '@/mixins/io.emit'
import broadcast from '@/mixins/broadcast.emit'

export default {
    sockets: {
        connect() {
            this.$store.commit('SET_SOCKETCONNECTION', true)
        },
        ...io,
        ...broadcast,
    },
}