import Vue from 'vue'

export default {
    SET_MEMBERS: ((state, payload) => {
        state.members = payload
    }),
    SET_MEMBER: ((state, payload) => {
        state.member = state.members.find(elem => elem.id === payload)
    }),
    SOCKET_MEMBER_SYNC: (state, payload) => {
        if (state.members.length) {
            const { id, status } = payload
            const index = state.members.findIndex(elem => elem.id === id)
            const member = state.members.find(elem => elem.id === id)

            member.feondi_uebertragungabgeschlossen = status

            Vue.set(state.members, index, member)

            if (Object.keys(state.member).length > 0 && state.member.id === id)
                Vue.set(state.member, 'feondi_uebertragungabgeschlossen', status)
        }
    },
    SET_ACT_AS_MEMBER: ((state, payload) => {
        state.actAsMember = payload
    }),
    SET_ACTING_MEMBERID: ((state, payload) => {
        state.actingMemberid = payload
    }),
    SET_MEMBER_CHANNELS: ((state, payload) => {
        state.memberChannels = payload
    }),
    SET_MEMBER_CHANNEL: ((state, payload) => {
        state.memberChannel = payload
    }),
    UPDATE_MEMBER_CHANNEL: (state, payload) => {
        state.memberChannels[payload.channelsIndex][payload.index].ss_activatedformember = payload.status

        //Info: If the current channel to be disabled is FBE (32), then also disable Google (31),
        // since Google cannot be booked without an activated FBE channel
        if (!payload.status && state.memberChannels[payload.channelsIndex][payload.index].ss_id === 32) {
            const indexOfGoogle = state.memberChannels[1].findIndex(elem => elem.ss_id === 31)
            state.memberChannels[1][indexOfGoogle].ss_activatedformember = false
        }
    },
    SET_INTERFACES: ((state, payload) => {
        state.interfaces = payload
    }),
    SET_INTERFACE: ((state, payload) => {
        if (typeof payload === 'number')
            state.interface = state.interfaces.find(elem => elem.ms_id === payload)
        else if (typeof payload === 'object' && !Array.isArray(payload) && payload.hasOwnProperty('ms_id'))
            state.interface = payload
    }),
    SET_CHANNELPROPERTIES: ((state, payload) => {
        state.channelProperties = payload
    }),
    SET_CHANNELPROPERTY: ((state, payload) => {
        state.channelProperty = state.channelProperties.find(elem => elem.obje_objid === payload)
    }),
    SET_POLICIES: ((state, payload) => {
        state.policies = payload
    }),
    SET_CLIENTS: ((state, payload) => {
        state.clients = payload
    }),
    SET_CLIENT: ((state, payload) => {
        state.client = state.clients.find(elem => elem.mandant_id === payload)
    }),
}