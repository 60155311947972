<template>
    <v-dialog
        v-if="checkShowChangelog()"
        persistent
        style="max-height: 600px"
        v-model="dialog"
        max-width="550"
        :overlay-color="getColors.primary"
    >
        <v-card tile>
            <div style="position: sticky; top: 0; z-index: 999; background-color: #ffffff; border-bottom: 1px solid rgba(0, 0, 0, 0.12)">
                <v-card-title>
                    <div class="d-flex flex-row align-center">
                        <div>
                            <v-img
                                class="mr-4"
                                contain
                                :src="require('@/assets/images/logos/character-single-woman-sitting.svg')"
                                :lazy-src="require('@/assets/images/logos/character-single-woman-sitting.svg')"
                                max-width="40px"
                            />
                        </div>
                        <div class="overline text-h5">
                            Whats new in admin world?
                        </div>
                    </div>
                </v-card-title>
            </div>
            <div v-for="(changelog, changelogIndex) in changelogs" :key="`changelog-${changelogIndex}`">
                <v-card-subtitle class="pb-0">
                    {{ changelog.date }} (v. {{ changelog.version.major }}.{{ changelog.version.minor }}.{{ changelog.version.build }})
                </v-card-subtitle>
                <v-card-title class="pt-0 headline lighten-2">
                    🚀 {{ changelog.title }}
                </v-card-title>
                <v-img
                    v-if="changelog.hasOwnProperty('image') && changelog.image"
                    contain
                    :src="require(`../../assets/images/${changelog.image}`)"
                    :lazy-src="require(`../../assets/images/${changelog.image}`)"
                />
                <v-card-text v-html="changelog.description"/>
                <div v-for="(fixOrFeature, fixOrFeatureIndex) in fixesOrFeatures" :key="`fixOrFeature-${fixOrFeatureIndex}`">
                    <v-list v-if="changelog.hasOwnProperty(fixOrFeature.changelogType) && changelog[fixOrFeature.changelogType].length">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="mb-0 heading lighten-4">
                                    {{ fixOrFeature.title }}
                                </v-list-item-title>
                                <ul>
                                    <li
                                        v-for="(changelogEntry, changelogEntryIndex) in changelog[fixOrFeature.changelogType]"
                                        :key="`changelogEntry-${changelogEntryIndex}`"
                                        class="v-card__text pb-0"
                                    >
                                        <span class="font-weight-bold">
                                            {{ changelogEntry.title }}
                                        </span>
                                        <a
                                            v-if="changelogEntry.ticketId"
                                            :href="`https://jira.xsigns.de:8443/browse/${changelogEntry.ticketId}`"
                                            target="_blank"
                                        >
                                            {{ changelogEntry.ticketId }}
                                        </a>
                                        <span class="font-weight-bold">:<br></span>
                                        <span v-html="changelogEntry.description"/>
                                    </li>
                                </ul>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
                <v-divider v-if="changelogIndex < (changelogs.length - 1)" class="my-3"/>
            </div>
            <div style="position: sticky; bottom: 0; z-index: 999; background-color: #ffffff; border-top: 2px solid rgba(0, 0, 0, 0.12)">
                <v-card-actions>
                    <v-checkbox
                        class="mt-0 ml-2 pa-0"
                        ripple
                        markAsRead
                        v-model="markAsRead"
                        :color="$store.getters.getColors.secondary1"
                        label="mark as read"
                        :value="true"
                        hide-details
                    />
                    <v-spacer/>
                    <v-btn
                        class="ma-0"
                        :color="getColors.secondary1"
                        text
                        @click="closeChangelog"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import Dialog from '@/components/Allgemein/Dialog'
import { mapGetters } from 'vuex'
import changelogs from '@/utils/changelogs'

export default {
    name: 'ChangelogDialog',
    components: { Dialog },
    data() {
        return {
            dialog: true,
            markAsRead: (this.$cookies.isKey('checkedMarkAsRead') && this.$cookies.get('checkedMarkAsRead')),
            changelogs: this.getFilteredChangelogs(changelogs).reverse(),
            fixesOrFeatures: [
                {
                    changelogType: 'newFeatures',
                    title: 'New Features 👇',
                },
                {
                    changelogType: 'bugfixes',
                    title: 'Bugfixes 🐛',
                },
                {
                    changelogType: 'improvements',
                    title: 'Improvements 💨',
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
        ]),
    },
    methods: {
        checkShowChangelog() {
            //logger.log(`this is changelogReadForSession: ${ this.$cookies.get('changelogReadForSession') }`)

            if (this.$store.getters.isAuthenticated) {
                if ((this.$cookies.isKey('changelogReadForSession') && this.$cookies.get('changelogReadForSession')))
                    return false

                //Info: Known user with appversion in cookie
                if (this.$cookies.isKey('appVersion')) {
                    const storedAppVersion = this.$cookies.get('appVersion')
                    const lastPublishedIndex = (changelogs.filter(elem => elem.hasOwnProperty('published') && elem.published === true).length - 1)
                    const currentAppVersion = changelogs[lastPublishedIndex].version

                    const storedMajor = parseInt(storedAppVersion.major)
                    const storedMinor = parseInt(storedAppVersion.minor)
                    const storedBuild = parseInt(storedAppVersion.build)

                    const currentMajor = parseInt(currentAppVersion.major)
                    const currentMinor = parseInt(currentAppVersion.minor)
                    const currentBuild = parseInt(currentAppVersion.build)

                    return ((storedMajor < currentMajor) || (storedMajor <= currentMajor && storedMinor < currentMinor) || (storedMajor <= currentMajor && storedMinor <= currentMinor && storedBuild < currentBuild))
                }

                return true
            }

            return false
        },
        getFilteredChangelogs(changeLogs) {
            if (this.$cookies.isKey('appVersion')) {

                const currentMajor = parseInt(this.$cookies.get('appVersion').major)
                const currentMinor = parseInt(this.$cookies.get('appVersion').minor)
                const currentBuild = parseInt(this.$cookies.get('appVersion').build)

                return changeLogs.filter(elem => {
                    return (elem.published && (elem.version.major > currentMajor || (elem.version.major >= currentMajor && elem.version.minor > currentMinor) || (elem.version.major >= currentMajor && elem.version.minor >= currentMinor && elem.version.build > currentBuild)))
                })
            }

            return changeLogs.filter(elem => elem.hasOwnProperty('published') && elem.published)
        },
        async closeChangelog() {
            await this.$store.dispatch('cacheClear', { routing: false, reload: true })

            this.dialog = false
            const lastPublishedIndex = (changelogs.filter(elem => elem.hasOwnProperty('published') && elem.published === true).length - 1)
            const version = changelogs[lastPublishedIndex].version

            //logger.log(`looking for the last index in changelogs array: `, version)

            if (this.markAsRead) {
                this.$cookies.set('appVersion', version)
                this.$cookies.set('checkedMarkAsRead', true)
            } else
                this.$cookies.set('changelogReadForSession', true, 0)
        },
    },
}
</script>
