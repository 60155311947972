export default {

    /**
     *
     * @param message
     * @param object
     */
    log: (message, object) => {
        if(parseInt(process.env.VUE_APP_DEBUG)) {
            if(typeof message === 'object') {
                object = message
                message = ''
            }
            else if(typeof object === 'boolean' || typeof object === 'number' || typeof object === 'bigint') {
                message = message + object
                object = ''
            }
            else if(typeof object === 'string' || object === undefined)
                object = ''

            console.log(message, object)
        }
    },
}