import defaultState from '@/store/defaultState'
import Vue from 'vue'
import I18n from '@/i18n/index'
import _ from 'lodash'

export default {
    SET_TASK: ((state, payload) => {
        state.task = _.clone(payload)
    }),
    SET_SOCKETCONNECTION: ((state, payload) => {
        state.socketConnection = payload
    }),
    SET_COLORS: ((state, payload) => {
        for (const i in payload) {
            if (payload.hasOwnProperty(i)) {
                state.colors[i] = payload[i]
            }
        }
    }),
    SET_ALERT: ((state, payload) => {
        state.alert = payload
    }),
    SET_BANNER: ((state, payload) => {
        state.banner = payload
    }),
    SET_BREADCRUMBS: ((state, payload) => {
        state.breadcrumbs = payload
    }),
    UPDATE_BREADCRUMBS: ((state, payload) => {
        const breadcrumbs = state.breadcrumbs
        const from = payload.from
        const to = payload.to
        const toFirstLevelSegment = to.fullPath.substring(1).split('/').length === 1

        logger.log(`this is toFirstLevelSegment: ${ toFirstLevelSegment }: `, to.fullPath.substring(1).split('/'))

        let index = breadcrumbs.findIndex(element => element.text === to.name)

        const newItem = {
            text: to.name,
            disabled: false,
            to: to.fullPath,
        }

        //Info: Remove all segments if roouting to first level segment path
        if (toFirstLevelSegment)
            state.breadcrumbs.splice(0)

        //Info: Add breadcrumbs
        if (to.meta.breadcrumb && !state.breadcrumbs.includes(newItem))
            state.breadcrumbs.push(newItem)

        //Info: Remove breadcrumbs
        if (from.name !== null && !toFirstLevelSegment) {
            if (index > -1)
                state.breadcrumbs.splice(index)
        }
    }),
    SET_MODELS: ((state, payload) => {
        state.models = payload
    }),
    SET_FORM: ((state, payload) => {
        state.form = payload
    }),
    RESTORE_FORM: (state => {
        state.form = Object.assign(state.form, state.formCached)
    }),
    SET_FORMCACHED: ((state, payload) => {
        state.formCached = payload
    }),
    UPDATE_FORM: ((state, payload) => {

        logger.log(`form will be updated: `, payload)

        state.form = Object.assign(state.form, { [payload.key]: payload.value })
    }),
    SET_CHIPS: ((state, payload) => {
        if (payload.hasOwnProperty('memberid') && payload.hasOwnProperty('properties'))
            state.chips[payload.memberid].properties = payload.properties
        else
            state.chips = payload
    }),
    REMOVE_FROM_CHIPS: ((state, payload) => {

        if (payload.hasOwnProperty('memberid') && !payload.hasOwnProperty('objid'))
            Vue.delete(state.chips, payload.memberid)
        else if (payload.hasOwnProperty('memberid') && payload.hasOwnProperty('objid')) {
            const index = state.chips[payload.memberid].properties.findIndex(elem => elem.value === payload.objid)
            Vue.delete(state.chips[payload.memberid].properties, index)
        }

        logger.log(`this is new state chips: `, state.chips)
    }),
    RESET_ALL: state => {
        const alert = state.alert

        Object.assign(state, defaultState)
        state.alert = alert
    },
    RESET_PROP: ((state, payload) => {

        let arrWrapper = []

        if (!_.isArray(payload) && _.isString(payload))
            arrWrapper.push(payload)
        else
            arrWrapper = payload

        arrWrapper.forEach(element => {
            if (_.isArray(state[element]))
                state[element].splice(0)
            else if (_.isObject(state[element]))
                state[element] = Object.assign({}, defaultState[element])
            else if (_.isNumber(state[element]) || _.isString(state[element]))
                state[element] = defaultState[element]
        })
    }),
    SET_LOADERS: ((state, payload) => {
        const key = Object.keys(payload)[0]
        Vue.set(state.loaders, key, payload[key])
    }),
    SET_DISABLES: ((state, payload) => {
        const key = Object.keys(payload)[0]
        Vue.set(state.disables, key, payload[key])
    }),
    SET_MESSAGES: ((state, payload) => {
        const key = Object.keys(payload)[0]
        Vue.set(state.messages, key, payload[key])
    }),
    SET_KEY: ((state, payload) => {
        state.key = payload
    }),
    SET_TAB: ((state, payload) => {
        state.tab = payload
    }),
    SET_STEP: ((state, payload) => {
        state.step = payload
    }),
    SET_DIALOG: ((state, payload) => {
        state.dialog.state = payload.state
        state.dialog.data = payload.data
    }),
    SET_ROUTING: ((state, payload) => {
        if (payload.hasOwnProperty('fullPath'))
            Vue.set(state.routing, 'fullPath', payload.fullPath)

        if (payload.hasOwnProperty('params'))
            Vue.set(state.routing, 'params', payload.params)
    }),
    SET_LOCALE: ((state, payload) => {
        state.locale = payload
        I18n.locale = state.locale
        Vue.$cookies.set('locale', state.locale)
    }),
}