export default [
    {
        path: '/properties',
        name: 'Properties',
        meta: {
            requiresAuth: true,
            breadcrumb: true,
            menuGroup: 'properties',
        },
        component: () => import('@/views/Objekte.vue'),
    },
    {
        path: '/property/:propertyid',
        name: 'Property',
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumb: true,
            menuGroup: 'properties',
        },
        component: () => import('@/views/Objekt.vue'),
    },
]