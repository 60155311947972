import auth from '@/store/actions/auth'
import common from '@/store/actions/common'
import members from '@/store/actions/members'
import channels from '@/store/actions/channels'

export default {
    ...auth,
    ...common,
    ...members,
    ...channels,
}