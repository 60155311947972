import auth from '@/store/getters/auth'
import common from '@/store/getters/common'
import members from '@/store/getters/members'
import channels from '@/store/getters/channels'
import queue from '@/store/getters/queue'

export default {
    ...auth,
    ...common,
    ...members,
    ...channels,
    ...queue,
}