import router from '@/router'
import AuthService from '@/services/auth.service'
import Axios from 'axios'
import Vue from 'vue'
import { load } from 'recaptcha-v3'

export default {
    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    login: async(context, payload) => {

        if (process.env.VUE_APP_ENV === 'production') {
            const recaptcha = await load(process.env.VUE_APP_SITEKEY, {
                useRecaptchaNet: true,
                autoHideBadge: true,
            })

            payload.recaptchaToken = await recaptcha.execute('login')
        }
        else
            payload.recaptchaToken = process.env.VUE_APP_SITEKEY_LOCALHOST

        const response = await AuthService.login(payload)
        if (response && response.hasOwnProperty('token') && response.token) {
            await context.dispatch('cacheClear')

            context.commit('SET_TOKEN', response.token)
            context.commit('SET_USER', response.user)

            Axios.defaults.headers.common['Authorization'] = `Bearer ${ response.token }`

            Vue.$cookies.set('user', {
                token: response.token,
                expires: response.expires,
                username: payload.username,
                password: btoa(payload.password),
                firstname: response.user.firstname,
                name: response.user.name,
                extendedSession: payload.extendedSession,
            })

            let routerPushObject = {
                name: 'Members',
            }

            if (Object.keys(context.getters.getRouting.params).length) {
                const params = context.getters.getRouting.params

                if (params.hasOwnProperty('agb')) {
                    routerPushObject = {
                        path: `/member/${ params.memberid }/member-channel/${ params.ssid }/interface/${ params.msid }/${ params.agb }/${ params.s }`,
                    }
                }

            }

            await router.push(routerPushObject)

            const credentialsRefreshToken = {
                token: response.token,
                username: payload.username,
                password: payload.password,
                extendedSession: payload.extendedSession,
                immediately: false,
            }

            await context.dispatch('refreshToken', credentialsRefreshToken)
        }
        else
            await context.dispatch('logout')
    },

    /**
     *
     * @param context
     * @param payload
     */
    refreshToken(context, payload) {
        const delayBasedOnSelectedSessionLength = (payload.immediately ? 0 : 60 * 5 * 1000)

        //logger.log('this is delayBasedOnSelectedSessionLength: ', delayBasedOnSelectedSessionLength)

        setTimeout(async() => {
            if (context.getters.isAuthenticated) {
                const response = await AuthService.refreshToken(payload)

                if (response && response.hasOwnProperty('token') && response.token) {
                    const refreshedToken = response.token
                    context.commit('SET_TOKEN', refreshedToken)

                    payload.token = refreshedToken
                    payload.immediately = false

                    await context.dispatch('refreshToken', payload)
                }
                else
                    await context.dispatch('logout')
            }
            else
                await context.dispatch('logout')

            //Info: Send new refreshToken request each 5 minutes
        }, delayBasedOnSelectedSessionLength)
    },

    /**
     *
     * @param context
     * @returns {Promise<void>}
     */
    checkToken: async context => {
        if (context.getters.isAuthenticated) {
            const currentToken = context.getters.getToken
            const resCheckToken = await AuthService.checkToken({ token: currentToken })

            logger.log(`this is response checkToken: `, resCheckToken)

            if (!resCheckToken || !resCheckToken.hasOwnProperty('checkToken') || !resCheckToken.checkToken || !Vue.$cookies.isKey('user'))
                await context.dispatch('logout')
            else {
                const cookieUser = Vue.$cookies.get('user')

                const credentials = {
                    token: currentToken,
                    username: cookieUser.username,
                    password: atob(cookieUser.password),
                    extendedSession: cookieUser.extendedSession,
                    immediately: true,
                }

                await context.dispatch('refreshToken', credentials)
            }
        }
    },

    /**
     *
     * @param context
     * @param routing
     * @returns {Promise<Route>}
     */
    logout: async(context, routing = true) => {
        if (routing && router.history.current.path !== '/' && router.history.current.path !== '/login')
            await router.push({ path: '/login' })

        context.commit('RESET_ALL')
    },
}