<template>
    <v-overlay :value="!!$store.getters.getLoaders.cacheClear || !!$store.getters.getLoaders.prepareApp">
        <v-progress-circular
            class="mr-4"
            indeterminate
            size="64"
        />
        {{ !!$store.getters.getLoaders.prepareApp ? 'Preparing application 🤖' : 'Clearing cache 🧹' }}
    </v-overlay>
</template>

<script>
export default {
    name: 'CacheClearOverlay',
}
</script>
