<template>
    <div class="w-100 pl-4 pb-2 mt-11" v-if="$store.getters.isAuthenticated">
        <v-breadcrumbs :items="items" class="pa-0">
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                    :to="item.to"
                    :disabled="item.disabled"
                    exact
                    ripple
                >
                    {{ $t(`common.breadcrumbs.${ getKeyWithoutSpecialChars(item.text) }`).toUpperCase() }}
                </v-breadcrumbs-item>
            </template>
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumbs',
    computed: {
        items() {
            return this.$store.getters.getBreadcrumbs
        },
    },
    methods: {
        getKeyWithoutSpecialChars(itemText) {
            return itemText.replace(/[^\w\s]|\s/gi, '').toLowerCase()
        },
    },
}
</script>
