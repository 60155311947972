import communicate from './communicate.service'

export default {
    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async login(payload) {
        return await communicate.request(enums.POST, 'login', payload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async checkToken(payload) {
        return await communicate.request(enums.POST, 'checktoken', payload)
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async refreshToken(payload) {
        return await communicate.request(enums.POST, 'refreshtoken', payload)
    },
}