import MembersService from '@/services/members.service'
import router from '@/router'
import Vue from 'vue'

export default {

    actAsMember: async (context, payload) => {

        const memberidFromCurrentMember = context.getters.getMember.id

        if (!('resetMember' in payload))
            payload.resetMember = false

        if (context.getters.getActAsMember && memberidFromCurrentMember && payload.id && memberidFromCurrentMember === payload.id)
            await context.dispatch('stopActingAsMember')
        else
            await context.dispatch('startActingAsMember', payload.id)
    },

    /**
     *
     * @param context
     * @param memberidToActAs
     * @returns {Promise<void>}
     */
    startActingAsMember: async (context, memberidToActAs) => {
        context.commit('SET_MEMBER', memberidToActAs)
        context.commit('SET_ACTING_MEMBERID', memberidToActAs)
        context.commit('SET_ACT_AS_MEMBER', true)
    },

    /**
     *
     * @param context
     * @param redirect
     * @returns {Promise<void>}
     */
    stopActingAsMember: async (context, redirect = false) => {

        if (redirect)
            await router.push({path: '/members'})

        context.commit('SET_MEMBER', context.getters.getUser.id)
        context.commit('SET_ACTING_MEMBERID', context.getters.getUser.id)
        context.commit('SET_ACT_AS_MEMBER', false)

        await context.dispatch('resetPropsAfterActingMember')
    },

    /**
     *
     * @param context
     * @param force
     * @returns {Promise<void>}
     */
    getAndSetMembers: async (context, force = false) => {

        //Info: Don't need to fetch members again because they are already present in store
        if (force || !context.getters.getMembers.length) {
            await context.dispatch('setLoaders', {members: true})

            const response = await MembersService.getMembers()

            if (response) {
                context.commit('SET_MEMBERS', response)

                if (Object.keys(context.getters.getMember).length && context.getters.getTab === 0) {
                    context.commit('SET_MEMBER', context.getters.getMember.id)
                    await context.dispatch('setForms', context.getters.getMember)
                }
            }

            await context.dispatch('setLoaders', {members: false})
        }
    },

    /**
     *
     * @param context
     * @param ssid
     * @returns {Promise<void>}
     */
    getAndSetChannelMembers: async (context, ssid) => {
        await context.dispatch('setLoaders', {channelmembers: true})

        const response = await MembersService.getChannelMembers(ssid)

        if (response)
            context.commit('SET_MEMBERS', response)

        await context.dispatch('setLoaders', {channelmembers: false})
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    getAndSetMemberChannels: async (context, payload) => {

        if ((payload.force || !context.getters.getMemberChannels.length) && payload.memberid === parseInt(router.currentRoute.params.memberid)) {
            await context.dispatch('setLoaders', {channels: true})

            const response = await MembersService.getMemberChannels(payload.memberid)

            if (response)
                context.commit('SET_MEMBER_CHANNELS', response)

            await context.dispatch('setLoaders', {channels: false})
        }
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    updateAndSetMemberChannel: async (context, payload) => {

        await context.dispatch('setLoaders', {[payload.ssid]: true})

        const response = await MembersService.setMemberChannel(payload)

        if (response)
            context.commit('UPDATE_MEMBER_CHANNEL', payload)

        await context.dispatch('setLoaders', {[payload.ssid]: false})
        await context.dispatch('successfulBanner', `${ payload.textid } successfully ${ (payload.status) ? 'activated' : 'deactivated' } for member ${ payload.memberid }!`)
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    getAndSetInterfaces: async (context, payload) => {

        if ((payload.force || !context.getters.getInterfaces.length) && payload.memberid === parseInt(router.currentRoute.params.memberid)) {
            const ssid = router.currentRoute.params.ssid

            if (ssid) {
                await context.dispatch('setLoaders', {interfaces: true})
                await context.dispatch('setLoaders', {fields: true})

                const response = await MembersService.getMemberInterfaces({memberid: payload.memberid, ssid: ssid})

                if (response) {
                    context.commit('SET_INTERFACES', response)

                    if (Object.keys(context.getters.getInterface).length) {
                        context.commit('SET_INTERFACE', context.getters.getInterface.ms_id)
                        await context.dispatch('setForms', context.getters.getInterface)
                    }
                }

                await context.dispatch('setLoaders', {fields: false})
                await context.dispatch('setLoaders', {interfaces: false})
            }
        }
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    getAndSetChannelProperties: async (context, payload) => {

        if ((payload.force || !context.getters.getChannelProperties.length) && payload.memberid === parseInt(router.currentRoute.params.memberid)) {
            const ssid = router.currentRoute.params.ssid

            if (ssid) {
                await context.dispatch('setLoaders', {channelproperties: true})

                const response = await MembersService.getChannelProperties({
                    memberid: payload.memberid,
                    ssid: ssid,
                })

                if (response)
                    context.commit('SET_CHANNELPROPERTIES', response)

                await context.dispatch('setLoaders', {channelproperties: false})
            }
        }
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    getAndSetPolicies: async (context, payload) => {

        if ((payload.force || !Object.keys(context.getters.getPolicies).length) && payload.memberid === parseInt(router.currentRoute.params.memberid)) {
            await context.dispatch('setLoaders', {fields: true})

            const response = await MembersService.getMemberPolicies({memberid: payload.memberid})

            if (response) {
                context.commit('SET_POLICIES', response[0])

                if (context.getters.getTab === 2)
                    await context.dispatch('setForms', context.getters.getPolicies)
            }

            await context.dispatch('setLoaders', {fields: false})
        }
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    getAndSetClients: async (context, payload) => {

        if (payload.force || !context.getters.getClients.length) {

            await context.dispatch('setLoaders', {clients: true})

            const response = await MembersService.getClients({
                memberid: payload.memberid,
            })

            if (response)
                context.commit('SET_CLIENTS', response)

            await context.dispatch('setLoaders', {clients: false})
        }
    },
}