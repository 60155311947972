export default [
    {
        path: '/channels',
        name: 'Channels',
        meta: {
            requiresAuth: true,
            breadcrumb: true,
            menuGroup: 'channels',
        },
        component: () => import('@/views/Portale.vue'),
    },
    {
        path: '/channel/:ssid',
        name: 'Channel',
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumb: true,
            hasForm: true,
            menuGroup: 'channels'
        },
        component: () => import('@/components/Portale/Portal.vue'),
    },
]