import store from '@/store/index'

export default {

    beforeResolve: ((to, from, next) => {
        let checkRequirements = true
        let changeDefaultRoute = false
        let specificPath = ''


        //Info: Set queries in the store when selected paramters are requested.
        if (to.query.hasOwnProperty('agb'))
            store.commit('SET_ROUTING', { params: to.query, fullPath: to.fullPath })

        if (Object.keys(store.getters.getRouting.params).length) {
            if (store.getters.getRouting.params.hasOwnProperty('agb')) {
                checkRequirements = false
                changeDefaultRoute = true

                const params = store.getters.getRouting.params
                specificPath = `/member/${ params.memberid }/member-channel/${ params.ssid }/interface/${ params.msid }/${ params.agb }/${ params.s }`

                if (to.fullPath === specificPath)
                    changeDefaultRoute = false
            }
        }

        switch (true) {
            //Info: Check if the route has the permission property and if an access permission is granted
            case to.matched.some(record => record.meta.hasOwnProperty('permission') && !record.meta.permission()):
                next({ name: 'Forbidden' })
                break

            //Info: Check if the route routes to /forbidden and if "from" has the property permission or if an access
            // permission is given, otherwise redirect to 404 if navigating to /forbidden for no reason
            case to.name.toLowerCase() === 'forbidden':
                if (from.matched.some(record => (!record.meta.hasOwnProperty('permission') || (record.meta.hasOwnProperty('permission') && record.meta.permission))))
                    next()
                else
                    next({ name: 'NotFound' })
                break

            //Info: Check if user is authenticated to navigate on protected routes
            case to.matched.some(record => record.meta.requiresAuth && !store.getters.isAuthenticated):
                next({ name: 'Login' })
                break

            //Info: Check if the required data is available in the store to navigate to the route
            case checkRequirements && to.matched.some(record => record.meta.requiresMembers && !store.getters.getMembers.length):
            case checkRequirements && to.matched.some(record => record.meta.requiresMember && !Object.keys(store.getters.getMember).length):
            case checkRequirements && to.matched.some(record => record.meta.requiresMemberChannels && !store.getters.getMemberChannels.length):
            case checkRequirements && to.matched.some(record => record.meta.requiresMemberChannel && !Object.keys(store.getters.getMemberChannel).length):

                let pathNameDirectingTo = 'Login'

                switch (to.name.toLowerCase()) {
                    case 'member':
                    case 'member-channel':
                    case 'interface':
                        pathNameDirectingTo = 'Members'
                        break

                    case 'property':
                        pathNameDirectingTo = 'Properties'
                        break

                    case 'house':
                        pathNameDirectingTo = 'Houses'
                        break

                    case 'channel':
                        pathNameDirectingTo = 'Houses'
                        break
                }

                store.commit('SET_BANNER', {
                    type: 'error',
                    message: `Load the required ${ pathNameDirectingTo.toLowerCase() } data before you requesting details of it`,
                    icon: 'mdi-alert-circle-outline',
                    btnText: 'Ok',
                    action: false,
                })

                next({ name: pathNameDirectingTo })

                break

            default:
                store.commit('UPDATE_BREADCRUMBS', { from: from, to: to })

                if (changeDefaultRoute && store.getters.isAuthenticated)
                    next({ path: specificPath })
                else
                    next()
        }
    }),

    afterEach: (async (to, from) => {

        if (store.getters.isAuthenticated && from.hasOwnProperty('meta') && from.meta.hasOwnProperty('menuGroup')) {
            const fromGroup = from.meta.menuGroup
            const toGroup = to.meta.menuGroup

            if (toGroup !== fromGroup) {
                //Info: Define all properties from the store that should be cleared when leaving a certain menu group
                let storePropertyNames = []

                switch (fromGroup.toLowerCase()) {
                    case 'members':

                        //Info: Do not reset members & member, because they are always needed!
                        storePropertyNames = [
                            'form',
                            'formCached',
                            'policies',
                            'channel',
                            'channels',
                            'interfaces',
                            'interface',
                        ]

                        break

                    case 'properties':
                        storePropertyNames = [
                            'form',
                            'formCached',
                            'properties',
                            'property',
                            'propertyAmenities',
                            'propertyDistances',
                            'propertyImages',
                            'propertyOccupancies',
                            'propertyOccupancy',
                            'propertyOffers',
                            'propertyPrices',
                            'propertyServices',
                            'propertySurcharges',
                        ]

                        break

                    case 'houses':
                        storePropertyNames = [
                            'houses',
                            'house',
                            'houseImages',
                        ]

                        break
                }

                await store.dispatch('resetProps', storePropertyNames)
            }
        }
    }),
}
