<template>
    <v-app>
        <Banner/>
        <changelog-dialog/>
        <cache-clear-overlay/>
        <Header/>
        <Main/>
        <Footer/>
    </v-app>
</template>

<style>
@import './assets/styles/custom.css';
</style>

<script>
import CacheClearOverlay from '@/components/Allgemein/SpeicherLoeschenOverlay.vue'
import Header from '@/components/Layout/Header'
import Footer from '@/components/Layout/Footer'
import ChangelogDialog from '@/components/Allgemein/Aenderungsprotokolldetails.vue'
import Banner from '@/components/Allgemein/Banner'
import Main from '@/components/Layout/Main'
import socket from '@/mixins/socket.mixin'
import { mapGetters } from 'vuex'

export default {
    name: 'App',
    mixins: [socket],
    components: {
        CacheClearOverlay,
        Main,
        ChangelogDialog,
        Header,
        Footer,
        Banner,
    },
    metaInfo: {
        title: 'Home',
        titleTemplate: '%s → adminv2 🤖',
    },
    data() {
        return {
            banner: true,
            async callAsyncLogout() {
                await this.$store.dispatch('logout', false)
            },
        }
    },
    computed: {
        ...mapGetters([
            'isAuthenticated',
            'getSocketConnection',
            'getKey',
        ]),
    },
    methods: {},
    async created() {
        await this.$store.dispatch('resetProps', 'loaders')

        await this.$store.dispatch('checkToken')

        window.addEventListener('keydown', (e) => {
            if (this.getKey !== e.key)
                this.$store.commit('SET_KEY', e.key)
        })

        window.addEventListener('keyup', () => {
            if (this.getKey !== '')
                this.$store.commit('SET_KEY', '')
        })

        await utils.notification().permission()
    },
    async mounted() {
        window.addEventListener('beforeunload', () => {
            this.$store.dispatch('resetProps', ['breadcrumbs', 'alert'])
        })
    },
}
</script>
