import router from '@/router'
import _ from 'lodash'

export default {
    /**
     *
     * @param context
     * @param payload
     */
    setForm: (context, payload) => {
        context.commit('SET_FORM', payload)
    },

    /**
     *
     * @param context
     * @param payload
     */
    setForms: (context, payload) => {
        context.commit('SET_FORM', payload)
        context.commit('SET_FORMCACHED', _.clone(payload))
    },

    /**
     *
     * @param context
     * @param payload
     */
    setModels: (context, payload) => {
        context.commit('SET_MODELS', payload)
    },

    /**
     *
     * @param context
     */
    resetForms: context => {
        context.commit('RESET_PROP', 'models')
        context.commit('RESET_PROP', 'form')
        context.commit('RESET_PROP', 'formCached')
    },

    /**
     *
     * @param context
     */
    restoreForm: context => {
        context.commit('RESTORE_FORM')

        for (let i in context.getters.getLoaders) {
            if (context.getters.getLoaders.hasOwnProperty(i)) {
                context.commit('SET_LOADERS', { [i]: false })
            }
        }
    },

    /**
     *
     * @param context
     * @param payload
     */
    resetProps: (context, payload) => {
        if (_.isString(payload))
            context.commit('RESET_PROP', payload)
        else if (_.isArray(payload))
            payload.forEach(val => {
                context.commit('RESET_PROP', val)
            })
    },

    resetPropsAfterActingMember: async context => {

        const props = [
            'properties',
            'property',
            'propertyAmenities',
            'propertyDistances',
            'propertyImages',
            'propertyOccupancies',
            'propertyOccupancy',
            'propertyOffers',
            'propertyPrices',
            'propertyServices',
            'propertySurcharges',
            'house',
            'houses',
            'houseImages',
        ]

        await context.dispatch('resetProps', props)
    },

    /**
     *
     * @param context
     * @param payload
     */
    setLoaders: (context, payload) => {
        const prop = Object.keys(payload)
        let delay = 0

        //Info: If loader is set to false again, set an delay
        if (!payload[prop])
            delay = 750

        setTimeout(() => {
            context.commit('SET_LOADERS', payload)
        }, delay)
    },

    /**
     *
     * @param context
     * @param additionalMessage
     * @returns {Promise<void>}
     */
    successfulBanner: async(context, additionalMessage) => {
        context.commit('SET_BANNER', {
            type: 'success',
            message: (additionalMessage ? additionalMessage : 'Success!'),
            icon: 'mdi-check-all',
            btnText: 'Ok',
            action: false,
        })

        setTimeout(() => {
            context.commit('RESET_PROP', 'banner')
        }, 3500)
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    socketEventBanner: async(context, payload) => {
        context.commit('SET_BANNER', {
            type: 'warning',
            message: payload.message,
            icon: 'mdi-bell',
            btnText: 'Ok',
            action: false,
        })

        if (payload.autoHide) {
            setTimeout(() => {
                context.commit('RESET_PROP', 'banner')
            }, 3500)
        }
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    cacheClear: async(context, payload) => {
        let routing = true
        let reload = false

        if (payload && payload.hasOwnProperty('routing'))
            routing = payload.routing

        if (payload && payload.hasOwnProperty('reload'))
            reload = payload.reload

        const currentPath = router.history.current.path
        let loaderKey = 'cacheClear'

        if (currentPath.includes('login'))
            loaderKey = 'prepareApp'

        await context.dispatch('setLoaders', { [loaderKey]: true })

        //Info: Since the cache is cleared with every login, it must be checked from where routing is done, so that no double routing occurs.
        if (routing && currentPath !== '/' && currentPath !== '/login')
            await router.push({ path: '/' })

        const props = [
            'member',
            'banner',
            'loaders',
            'tab',
            'breadcrumbs',
            'members',
            'subChannels',
            'interfaces',
            'interface',
            'channelProperties',
            'channelProperty',
            'channelMembers',
            'policies',
            'propertyChannels',
            'propertyChannelMembers',
            'jobCreation',
            'form',
            'formCached',
            'model',
            'chips',
            'task',
        ]

        await context.dispatch('resetProps', props)
        await context.dispatch('setLoaders', { [loaderKey]: false })
        await context.commit('SET_ACT_AS_MEMBER', false)
        await context.commit('SET_ACTING_MEMBERID', context.getters.getUser.id)

        if (reload)
            await location.reload()
    },
}