import store from '../store/index'

export default {

    /**
     *
     * @desc For router path: /queue/job-creator
     * @returns {boolean}
     */
    job_creator: () => {
        return true
    },
}