<template>
    <div>
        <Menu :menu.sync="menu"/>
        <v-app-bar
            app
            dark
            dense
            flat
            clipped-left
            class="elevation-0 pl-0"
            :color="getColors.primary"
        >
            <div class="w-100 d-flex align-center">
                <div>
                    <v-btn
                        plain
                        icon
                        :ripple="false"
                        v-if="getSocketConnection && !isAuthenticated"
                        @click.stop="$router.push({path: ($route.name && $route.name.toLowerCase() !== 'login' ? '/login' : '/')})"
                    >
                        <v-icon>
                            {{ ($route.name && $route.name.toLowerCase() !== 'login' ? 'mdi-account-key' : 'mdi-home') }}
                        </v-icon>
                    </v-btn>
                    <v-btn
                        plain
                        icon
                        :ripple="false"
                        v-else-if="getSocketConnection && isAuthenticated"
                        @click.stop="menu = !menu"
                    >
                        <v-icon>
                            {{ (menu ? 'mdi-menu-open' : 'mdi-menu') }}
                        </v-icon>
                    </v-btn>
                </div>
                <div>
                    <v-btn
                        class="d-none d-sm-inline-flex pl-2"
                        text
                        plain
                        :ripple="false"
                        to="/"
                    >
                        <v-toolbar-title>
                            {{ appTitle }}
                            <span class="font-weight-light caption">
                                {{ getAppVersion() }}
                            </span>
                        </v-toolbar-title>
                    </v-btn>
                </div>
                <div class="ml-auto">
                    <act-as-member-button with-memberid/>
                </div>
            </div>
        </v-app-bar>
    </div>
</template>

<script>
import ActAsMemberButton from '@/components/Allgemein/AgiereAlsKundeSchaltflaeche.vue'
import Menu from '@/components/Layout/Menu'
import { mapGetters } from 'vuex'

export default {
    name: 'Header',
    components: {
        ActAsMemberButton,
        Menu,
    },
    data() {
        return {
            appTitle: process.env.VUE_APP_NAME,
            menu: true,
        }
    },
    computed: {
        ...mapGetters([
            'getUser',
            'getMember',
            'isAuthenticated',
            'getSocketConnection',
            'getColors',
            'getActAsMember',
        ]),
    },
    methods: {
        getAppVersion() {
            if (this.$cookies.isKey('appVersion')) {
                const appVersion = this.$cookies.get('appVersion')

                return `${ appVersion.major }.${ appVersion.minor }.${ appVersion.build }`
            }
        },
    },
}
</script>
<style>
.v-toolbar__content {
    padding: 0 !important;
}
</style>